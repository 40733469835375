const aggregateRecords = dataArray => {
  return dataArray.reduce((acc, current) => {
    if (!acc) {
      return {
        battery_soc: current.battery_soc,
        start_date: current.start_date,
        end_date: current.end_date,
        exported: current.exported,
        imported: current.imported,
        load_consumption: current.load_consumption,
        solar_generation: current.solar_generation,
      };
    }

    return {
      battery_soc: acc.battery_soc + current.battery_soc,
      start_date: Math.min(acc.start_date, current.start_date),
      end_date: Math.max(acc.end_date, current.end_date),
      exported: acc.exported + current.exported,
      imported: acc.imported + current.imported,
      load_consumption: acc.load_consumption + current.load_consumption,
      solar_generation: acc.solar_generation + current.solar_generation,
    };
  }, null);
};

export default aggregateRecords;
