import awsmobile from '../config/aws-exports';
import apiRequest from '../utils/apiRequest';

/**
 * Constant representing the kilowatts generated per solar panel.
 *
 * This value is used to calculate the total energy output of the solar panels
 * based on the number of panels a user has.
 */
export const KW_PER_PANEL = 0.4;

/**
 * Enum for hardware types
 *
 * Defines the available hardware types that can be used in the onboarding process or hardware-related API requests.
 *
 * @property {string} INVERTER - Represents the "inverter" hardware type.
 * @property {string} BATTERY - Represents the "battery" hardware type.
 */
export const HardwareTypes = {
  INVERTER: 'inverter',
  BATTERY: 'battery',
};

/**
 * Create user battery solar details
 *
 * Sends a POST request to create the user's battery solar details.
 *
 * @param {Object} details - The battery solar details to be saved.
 * @return {Promise<any>} - A promise that resolves to the response from the API.
 */
export const createUserBatterySolarDetails = async details =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/onboarding/user-battery-solar-details`,
    null,
    'POST',
    details,
  );

/**
 * Create user trace
 *
 * Sends a POST request to log the user's progress through the onboarding process.
 *
 * @param {string} stepCompleted - The step of the onboarding process that has been completed.
 * @param {Object} data - Additional data related to the completed step.
 * @return {Promise<any>} - A promise that resolves to the response from the API.
 */
export const createUserTrace = async (stepCompleted, data) =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/onboarding/user-trace`,
    null,
    'POST',
    { step_completed: stepCompleted, data },
  );

/**
 * Get supported hardware
 *
 * Sends a GET request to retrieve the list of supported hardware for a given hardware type.
 *
 * @param {string} hardwareType - The type of hardware for which to retrieve support information. Can be either "inverter" or "battery".
 * @return {Promise<any>} - A promise that resolves to the response from the API containing the supported hardware.
 */
export const getSupportedHardware = async hardwareType =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/onboarding/supported-hardware?hardware_type=${hardwareType}`,
  );

/**
 * Get 24 hours of system data
 *
 * Retrieves 24 hours of system data for a given user.
 *
 * @param {string|null} [userSub=null] - Optional user sub identifier.
 * @return {Promise<any>} - A promise that resolves to the system data.
 */
export const getTwentyHoursOfSystemData = async (userSub = null) =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/24-hours-of-system-data`,
    userSub,
  );

/**
 * Get analytics data
 *
 * Fetches analytics data for a given date.
 *
 * @param {string} date - The date for which to retrieve analytics data (format: YYYY-MM-DD).
 * @param {string|null} [userSub=null] - Optional user sub identifier.
 * @return {Promise<any>} - A promise that resolves to the analytics data.
 */
export const getAnalyticsData = async (date, userSub = null) =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/analytics-data?analysis_date=${date}`,
    userSub,
  );

/**
 * Get current behaviour forecast
 *
 * Fetches the current behaviour forecast for the user.
 *
 * @param {string|null} [userSub=null] - Optional user sub identifier.
 * @return {Promise<any>} - A promise that resolves to the behaviour forecast data.
 */
export const getCurrentBehaviourForecast = async (userSub = null) =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/current-behaviour-forecast`,
    userSub,
  );

/**
 * Get user details
 *
 * Retrieves the user's details from the server.
 *
 * @param {string|null} [userSub=null] - Optional user sub identifier.
 * @return {Promise<any>} - A promise that resolves to the user details.
 */
export const getUserDetails = async (userSub = null) =>
  await apiRequest(`${awsmobile.optimise_endpoint_auth}/user-details`, userSub);

/**
 * Update user details
 *
 * Sends a POST request to update the user's details.
 *
 * @param {Object} details - The user details to be updated.
 * @param {string|null} [userSub=null] - Optional user sub identifier.
 * @return {Promise<any>} - A promise that resolves to the server response.
 */
export const updateUserDetails = async (details, userSub = null) =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/user-details`,
    userSub,
    'POST',
    details,
  );

/**
 * Create inverter authentication details
 *
 * Sends a PUT request to create or update the user's inverter authentication details.
 *
 * @param {Object} details - The inverter authentication details to be updated.
 * @return {Promise<any>} - A promise that resolves to the server response.
 */
export const createInverterAuthentication = async details =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/inverter-authentication`,
    null,
    'PUT',
    details,
  );
