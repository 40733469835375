import { Query } from 'react-contentful';
import { Spinner } from 'react-bootstrap';
import GeneralError from '../ui/GeneralError';

const withContentfulTag = (
  WrappedComponent,
  contentfulTag,
  contentType = 'General',
) => {
  const HOC = props => (
    <Query
      contentType={contentType}
      query={{
        'fields.tag': contentfulTag,
      }}>
      {({ data, error, fetched, loading }) => {
        if (loading || !fetched) {
          return (
            <div className="text-center p-5">
              <Spinner animation="border" />
            </div>
          );
        }
        let decoratedProps = {
          contentfulTag,
          contentType,
          contentful: !!data?.items ? data?.items[0]?.fields : undefined,
          metadata: !!data?.items ? data?.items[0]?.metadata : undefined,
          sys: !!data?.items ? data?.items[0]?.sys : undefined,
          error,
          fetched,
          loading,
        };
        return fetched && !error && data?.items?.length ? (
          <WrappedComponent {...props} {...decoratedProps} />
        ) : (
          <GeneralError
            contentfulTag={contentfulTag}
            contentType={contentType}
            error={error}
          />
        );
      }}
    </Query>
  );
  return HOC;
};

export default withContentfulTag;
