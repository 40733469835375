import { getGeocodeForPostcode } from '../api/Location';

const prepareArrays = async (solarPanelArrayData, postcode) => {
  const geocode = await getGeocodeForPostcode(postcode);
  const arrays = await Object.values(solarPanelArrayData || {}).map(
    ({ orientation, panelCount, tilt }) => ({
      orientation: parseInt(orientation),
      tilt: parseInt(tilt),
      panel_count: panelCount,
      latitude: geocode.latitude,
      longitude: geocode.longitude,
    }),
  );
  return arrays;
};

export default prepareArrays;
