import React, { useContext, useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import cx from 'classnames';
import styled from 'styled-components';
import ErrorLabel from '../../ui/ErrorLabel';
import LoopSpinner from '../../ui/LoopSpinner';
import { OnboardingContext } from '../../context/OnboardingContext';
import { RadioWrapper } from '../../ui/Styled';
import InfoModal from '../../ui/Modal/InfoModal';
import sameDirection from '../../assets/svg/solar-panels-same-direction.svg';
import differentTwoDirections from '../../assets/svg/solar-panels-2-directions.svg';
import differentTwoDirectionsAlt from '../../assets/svg/solar-panels-2-1-directions.svg';
import differentThreeDirections from '../../assets/svg/solar-panels-3-directions.svg';

const StyledFormContainer = styled.div`
  margin-top: 2rem;
  text-align: left;

  @media (min-width: 576px) {
    margin-top: 3rem;
  }
`;

const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  text-decoration: underline !important;
  color: #ff0085;
  display: inline-block;
  font-size: 14px;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const StyledH4 = styled.h4`
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 12px;
`;

const StyledInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin-top: 1.5rem;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }
`;

const StyledModalInfo = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 1.25rem;

  svg {
    flex-shrink: 0;
    margin-top: 0.3rem;
  }

  p {
    font-size: 14px;
    line-height: 1.5;
    margin-left: 0.3rem;

    @media (min-width: 576px) {
      font-size: 16px;
    }
  }

  span {
    font-weight: bold;
    color: #ff0085;
  }

  @media (min-width: 992px) {
    justify-content: center;
  }
`;

const StyledSameDirectionImg = styled.img`
  width: 100%;
  height: auto;
  max-height: 250px;

  @media (min-width: 992px) {
    height: 250px;
    max-height: auto;
  }
`;

const StyledDifferentTwoDirectionsImg = styled.img`
  width: 100%;
  height: auto;
  max-height: 250px;

  @media (min-width: 992px) {
    height: 250px;
    max-height: auto;
  }
`;

const StyledDifferentTwoDirectionsAltImg = styled.img`
  width: 100%;
  height: auto;
  max-height: 320px;

  @media (min-width: 992px) {
    height: 320px;
    max-height: auto;
  }
`;

const StyledDifferentThreeDirectionsImg = styled.img`
  width: 100%;
  height: auto;
  max-height: 225px;

  @media (min-width: 992px) {
    height: 225px;
    max-height: auto;
  }
`;

const SolarPanelsContainer = () => {
  const history = useHistory();
  const { setProgressPercentage, setSolarPanelArrayCount } =
    useContext(OnboardingContext);
  const [showExampleModal, setShowExampleModal] = useState(false);

  const validationSchema = Yup.object().shape({
    direction: Yup.string().required('Please select a direction'),
    numDirections: Yup.number().when('direction', {
      is: 'different',
      then: Yup.number()
        .required('Please select the number of directions')
        .min(2)
        .max(10),
    }),
  });

  const onSubmit = values => {
    if (values?.direction === 'same') {
      setSolarPanelArrayCount(1);
    } else if (values?.direction === 'different') {
      setSolarPanelArrayCount(values?.numDirections);
    }

    history.push('/onboarding/solar-panels/details');
  };

  const UpArrow = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={12}
      className="mr-1">
      <path
        fill="#FF0085"
        fillRule="nonzero"
        d="M12.674 6.586c0-.266-.11-.524-.29-.711L7.3.789A1.005 1.005 0 0 0 6.588.5a.973.973 0 0 0-.703.289L.799 5.875a1.012 1.012 0 0 0-.297.71c0 .267.11.517.297.704l.586.586a.98.98 0 0 0 1.406 0l2.297-2.29v5.5c0 .563.469.915 1 .915h1c.531 0 1-.352 1-.914v-5.5l2.297 2.289a.98.98 0 0 0 .703.297c.266 0 .523-.11.711-.297l.586-.586c.18-.187.289-.437.289-.703Z"
      />
    </svg>
  );

  useEffect(() => {
    setProgressPercentage(40);
  }, []);

  return (
    <>
      <h1 className="mb-3">
        Do all your solar panels face in the same direction?
      </h1>
      <div>
        <p>
          Are your solar panels on different parts of your roof, and therefore
          facing in different directions?
        </p>
        <StyledButton type="button" onClick={() => setShowExampleModal(true)}>
          Show me an example
        </StyledButton>
        <StyledFormContainer>
          <Formik
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{ direction: '', numDirections: '' }}
            validateOnChange>
            {({ handleSubmit, isValid, isSubmitting, values }) => (
              <Form onSubmit={handleSubmit}>
                <Field name="direction">
                  {({ field, meta }) => (
                    <>
                      <RadioWrapper
                        className={cx({
                          selected: field.value === 'same',
                        })}>
                        <Form.Check
                          {...field}
                          type="radio"
                          id="more-less-than"
                          value="same"
                          label="Yes, they all face the same direction"
                        />
                      </RadioWrapper>
                      <RadioWrapper
                        className={cx({
                          selected: field.value === 'different',
                        })}>
                        <Form.Check
                          {...field}
                          type="radio"
                          id="radio-less-than"
                          value="different"
                          label="No, they face in different directions"
                        />

                        {values.direction === 'different' && (
                          <Field name="numDirections">
                            {({ field, meta }) => (
                              <Form.Group>
                                <Form.Label>
                                  Select number of directions
                                </Form.Label>
                                <Form.Control
                                  as="select"
                                  {...field}
                                  isValid={!meta.error && meta.touched}
                                  className={cx({ error: !!meta.error })}>
                                  <option value="">
                                    Select number of directions
                                  </option>
                                  {[...Array(3)].map((_, i) => (
                                    <option key={i + 2} value={i + 2}>
                                      {i + 2}
                                    </option>
                                  ))}
                                </Form.Control>
                                {meta.error && meta.touched && (
                                  <ErrorLabel label={meta.error} />
                                )}
                              </Form.Group>
                            )}
                          </Field>
                        )}
                      </RadioWrapper>
                    </>
                  )}
                </Field>

                <Form.Group>
                  {!isSubmitting ? (
                    <Button
                      variant="primary"
                      disabled={!isValid || isSubmitting}
                      block
                      type="submit">
                      Continue
                    </Button>
                  ) : (
                    <LoopSpinner />
                  )}
                </Form.Group>
              </Form>
            )}
          </Formik>
        </StyledFormContainer>
      </div>

      <InfoModal
        size="lg"
        show={showExampleModal}
        onClose={() => setShowExampleModal(false)}
        infoContent={
          <>
            <StyledH4>Do all your panels face in the same direction?</StyledH4>
            <p>
              Do you have different groups of panels facing in different
              directions, on different parts of your roof? Solar panels which
              are separated by a chimney or something else on the same part of
              the roof, are still considered to be in the same group. <br />
              Some examples of this are shown below:
            </p>
            <StyledInfoGrid>
              <div>
                <StyledSameDirectionImg
                  src={sameDirection}
                  alt="Solar panels facing in the same direction"
                />
                <StyledModalInfo>
                  <UpArrow />
                  <p>
                    These panels all face in the <span>same direction</span>
                  </p>
                </StyledModalInfo>
              </div>
              <div>
                <StyledDifferentTwoDirectionsImg
                  src={differentTwoDirections}
                  alt="Solar panels facing in 2 different directions"
                />
                <StyledModalInfo>
                  <UpArrow />
                  <p>
                    These panels face in <span>2</span> different directions
                  </p>
                </StyledModalInfo>
              </div>
              <div>
                <StyledDifferentTwoDirectionsAltImg
                  src={differentTwoDirectionsAlt}
                  alt="Solar panels facing in 2 different directions: west and south"
                />
                <StyledModalInfo>
                  <UpArrow />
                  <p>
                    These panels face in <span>2</span> different directions:
                    west and south
                  </p>
                </StyledModalInfo>
              </div>
              <div>
                <StyledDifferentThreeDirectionsImg
                  src={differentThreeDirections}
                  alt="Solar panels facing in 3 different directions"
                />
                <StyledModalInfo>
                  <UpArrow />
                  <p>
                    These panels face in <span>3</span> different directions
                  </p>
                </StyledModalInfo>
              </div>
            </StyledInfoGrid>
          </>
        }
      />
    </>
  );
};

export default SolarPanelsContainer;
