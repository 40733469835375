import { Auth } from 'aws-amplify';

export const getAuthIdToken = async () => {
  return await Auth.currentSession().then(res => res.getIdToken());
};

export const getCurrentUserInfo = async () => {
  const res = await Auth.currentUserInfo();
  if (!res) {
    console.log('Error: Auth.currentUserInfo() returned undefined');
  }
  return res;
};

export const isSignedIn = async () => {
  return await getAuthIdToken().then(res => !!res);
};

export const signOut = async history => {
  try {
    await Auth.signOut().then(() => {
      localStorage.clear();
      return (window.location.href = '/');
    });
  } catch (error) {
    console.log('Error signing out: ', error);
  }
};
