import styled from 'styled-components';
import RippleLoader from './RippleLoader';

const RippleWrapper = styled.div`
  margin: 15px 0;
  &:first-child {
    margin-top: 5px;
  }
  &:last-child {
    margin-bottom: 5px;
  }
`;

const RippleLoaderLines = ({ lines, className }) => {
  const maxLines = Math.min(lines, 4); // Ensure max is 4 lines
  const widths = [100, 75, 50, 25]; // Predefined widths for the lines

  return (
    <>
      {Array.from({ length: maxLines }).map((_, index) => (
        <RippleWrapper className={className}>
          <RippleLoader key={index} width={`${widths[index]}%`} />
        </RippleWrapper>
      ))}
    </>
  );
};

export default RippleLoaderLines;
