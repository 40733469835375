import * as Yup from 'yup';
import BaseConnectionContainer from './BaseConnectionContainer';

const InstagenConnectionContainer = () => {
  const fields = [
    {
      name: 'api_username',
      label: 'Plant name',
      validation: Yup.string()
        .required('Please enter your Plant name')
        .max(200, 'Plant name must be 200 characters or less'),
    },
  ];

  return (
    <BaseConnectionContainer
      fields={fields}
      inverterManufacturer="Instagen"
      exampleModalContent={
        <>
          <h4>Your Plant name:</h4>
          <ul>
            <li>
              We don’t yet have details on how to find the{' '}
              <strong>Plant name</strong> for your battery and don’t have an
              example. We assume it is somewhere on the website that Instagen or
              your installer provided access to.
            </li>
            <li>
              We will update this text as soon as we have more details. For the
              time being, please ask your installer or Instagen to help locate
              the Plant name.
            </li>
          </ul>
        </>
      }
    />
  );
};

export default InstagenConnectionContainer;
