import { createContext, useState } from 'react';
import { usePersistedState } from '../hooks';
import PropTypes from 'prop-types';

const OnboardingContext = createContext();

export const BATTERY_MANUFACTURERS = {
  // ALPHAESS: 'alphaeass',
  GIV_ENERGY: 'giv-energy',
  GROWATT: 'growatt',
  INSTAGEN: 'instagen',
};

const OnboardingProvider = ({ children }) => {
  const [isManualAddress, setIsManualAddress] = usePersistedState(
    'isManualAddress',
    false,
  );
  const [addressId, setAddressId] = usePersistedState('addressId', false);
  const [termsAndConditions, setTermsAndConditions] = usePersistedState(
    'termsAndConditions',
    undefined,
  );
  const [redirectUrl, setRedirectUrl] = usePersistedState('redirectUrl', '');
  const [hasOnboarded, setHasOnboarded] = usePersistedState(
    'hasOnboarded',
    false,
  );
  const initialUserState = {
    attributes: {
      given_name: '',
      family_name: '',
      email: '',
    },
  };
  const [currentUser, setUser] = usePersistedState(
    'currentUser',
    initialUserState,
  );
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = usePersistedState(
    'deleteError',
    undefined,
  );
  const [progressPercentage, setProgressPercentage] = usePersistedState(0);
  const [postcode, setPostcode] = usePersistedState('postcode', undefined);
  const [tariffId, setTariffId] = usePersistedState('tariffId', undefined);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState(undefined);
  const [isSaving, setIsSaving] = usePersistedState('isSaving', false);
  const [pvSystemSize, setPvSystemSize] = usePersistedState(
    'pvSystemSize',
    undefined,
  );
  const [solarPanelArrayCount, setSolarPanelArrayCount] = usePersistedState(
    'solarPanelArrayCount',
    undefined,
  );
  const [solarPanelArrayData, setSolarPanelArrayData] = usePersistedState(
    'solarPanelArrayData',
    undefined,
  );
  const [numPanels, setNumPanels] = usePersistedState('numPanels', undefined);
  const [hasSolarDiverter, setHasSolarDiverter] = usePersistedState(
    'hasSolarDiverter',
    undefined,
  );
  const [supplier, setSupplier] = usePersistedState('supplier', undefined);
  const [hasEv, setHasEv] = usePersistedState('hasEv', undefined);
  const [batteryManufacturer, setBatteryManufacturer] = usePersistedState(
    'batteryManufacturer',
    undefined,
  );
  const [manufacturerDetails, setManufacturerDetails] = usePersistedState(
    'manufacturerDetails',
    undefined,
  );
  const [battery, setBattery] = usePersistedState('battery', undefined);
  const [batteryCapacity, setBatteryCapacity] = usePersistedState(
    'batteryCapacity',
    undefined,
  );
  const [inverterManufacturer, setInverterManufacturer] = usePersistedState(
    'inverterManufacturer',
    undefined,
  );
  const [inverter, setInverter] = usePersistedState('inverter', undefined);
  const [hasExportLimit, setHasExportLimit] = usePersistedState(
    'hasExportLimit',
    undefined,
  );

  return (
    <OnboardingContext.Provider
      value={{
        isManualAddress,
        addressId,
        setAddressId,
        setIsManualAddress,
        redirectUrl,
        setRedirectUrl,
        termsAndConditions,
        setTermsAndConditions,
        hasOnboarded,
        setHasOnboarded,
        currentUser,
        setUser,
        showDeleteAccountModal,
        setShowDeleteAccountModal,
        isDeleting,
        setIsDeleting,
        deleteError,
        setDeleteError,
        showAlert,
        setShowAlert,
        alert,
        setAlert,
        isSaving,
        setIsSaving,
        tariffId,
        setTariffId,
        progressPercentage,
        setProgressPercentage,
        postcode,
        setPostcode,
        pvSystemSize,
        setPvSystemSize,
        solarPanelArrayCount,
        setSolarPanelArrayCount,
        solarPanelArrayData,
        setSolarPanelArrayData,
        numPanels,
        setNumPanels,
        hasSolarDiverter,
        setHasSolarDiverter,
        supplier,
        setSupplier,
        hasEv,
        setHasEv,
        batteryManufacturer,
        setBatteryManufacturer,
        battery,
        setBattery,
        batteryCapacity,
        setBatteryCapacity,
        inverterManufacturer,
        setInverterManufacturer,
        manufacturerDetails,
        setManufacturerDetails,
        inverter,
        setInverter,
        hasExportLimit,
        setHasExportLimit,
      }}>
      {children}
    </OnboardingContext.Provider>
  );
};

OnboardingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { OnboardingContext, OnboardingProvider };
