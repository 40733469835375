import React, { createRef } from 'react';
import { ConfirmSignUp } from 'aws-amplify-react';
import VerificationInput from 'react-verification-input';
import { Button, Card, Form } from 'react-bootstrap';
import LoginLayout from '../ui/Layout/LoginLayout';
import LoginCard from '../ui/LoginCard';
import './styles.scss';
import { Auth } from '@aws-amplify/auth';
import { ConsoleLogger as Logger } from '@aws-amplify/core';

const logger = new Logger('ConfirmSignUp');

/**
 * Amplify confirm signup screen
 */
export default class LoopConfirmSignUp extends ConfirmSignUp {
  /**
   * Class constructor
   * @param {Object} props Properties passed to this screen
   */
  constructor(props) {
    super(props);

    this._validAuthStates = ['confirmSignUp'];
    this.onKeyUp = this.onKeyUp.bind(this);

    this.state = {
      resentEmail: false,
      entryComplete: false,
    };

    this.validationInputRef = createRef();
  }

  /**
   * onKeyUp event handler
   * @param {Object} event The onKeyUp event
   */
  onKeyUp(event) {
    if (event.charCode === 13) {
      this.confirm();
    }
  }

  /**
   * validate event handler
   * @param {Object} event The validate event
   */
  validate(event) {
    const { value } = event.nativeEvent.target;
    this.setState({ entryComplete: value.length === 6 });
    this.handleInputChange(event);
  }

  /**
   * Reset the input field and validate the input component
   */
  resetInputAndValidationInputComponent() {
    this.inputs.code = 0;
    this.validationInputRef.current.state.tan = '';
    this.validationInputRef.current.state.previousTan = '';
    this.validationInputRef.current.state.selectedIndex = 0;
    this.validationInputRef.current.state.isActive = false;
    this.validationInputRef.current.state.isValidTan = false;
  }

  /**
   * Resend the sign up, so user is sent new code
   */
  resend() {
    const username = this.usernameFromAuthData() || this.inputs.username;
    if (!Auth || typeof Auth.resendSignUp !== 'function') {
      throw new Error(
        'No Auth module found, please ensure @aws-amplify/auth is imported',
      );
    }
    Auth.resendSignUp(username)
      .then(() => {
        logger.debug('code resent');
        // this is to clear any error, not ideal as get an empty red bar
        this.error('');
        this.setState({ resentEmail: true });
      })
      .catch(err => {
        this.error(err);
        this.setState({ resentEmail: false });
      });
  }

  /**
   * Main render method for this component
   * @param {Object} theme The theme configuration
   * @return {JSX.Element}
   */
  showComponent(theme) {
    const username = this.usernameFromAuthData();
    const { resentEmail, entryComplete } = this.state;

    return (
      <LoginLayout>
        <LoginCard>
          <Card.Title as="h1">Verify your email</Card.Title>
          {username && (
            <Card.Text>
              We sent a six digit verification code to:
              <span className="py-1 d-block"></span>
              <strong>{username}</strong>
              <span className="py-1 d-block"></span>
              Keep this window open while you check your inbox, then enter the
              code below.
              <span className="py-1 d-block"></span>
              If you don&apos;t see the email, you may need to check your spam
              folder, and ensure you&apos;ve typed in your email address
              correctly.
            </Card.Text>
          )}
          {!username && (
            <Card.Text>
              Please enter the email address that we sent your six digit
              verification code to, then enter the six digit code below.
              <span className="py-1 d-block"></span>
              If you don&apos;t see the email, you may need to check your spam
              folder, and ensure you&apos;ve typed in your email address
              correctly.
            </Card.Text>
          )}
          <Form
            className="text-left mt-2"
            onSubmit={e => {
              e.preventDefault();
              this.confirm();
              return false;
            }}>
            {!username && (
              <Form.Group>
                <Form.Label htmlFor="username">Email address</Form.Label>
                <Form.Control
                  id="confirm_signup_username"
                  key="username"
                  name="username"
                  onChange={e => {
                    e.target.value = e.target.value.toLowerCase().trim();
                    this.handleInputChange(e);
                  }}
                  type="email"
                  placeholder="Email address"
                  onKeyPress={this.onKeyUp}
                />
              </Form.Group>
            )}
            <Form.Group className="mb-2">
              <Form.Label htmlFor="username">
                Six digit verification code
              </Form.Label>
              <VerificationInput
                ref={this.validationInputRef}
                validChars="0-9"
                placeholder="0"
                removeDefaultStyles={true}
                inputField={{
                  name: 'code',
                  onChange: event => this.validate(event),
                }}
                container={{
                  className: 'verification-input-container',
                }}
                characters={{
                  className: 'verification-input-characters',
                }}
                character={{
                  className: 'verification-input-character',
                  classNameInactive: 'verification-input-character--inactive',
                  classNameSelected: 'verification-input-character--selected',
                }}
                autoFocus={!!username}
              />
            </Form.Group>
            <Form.Group className="mb-0">
              {resentEmail ? (
                <p className="mb-0">Email resent</p>
              ) : (
                <p className="mb-0">
                  Didn&apos;t get the code?{' '}
                  <Button
                    variant="inline-link"
                    onClick={() => {
                      this.resend();
                      this.resetInputAndValidationInputComponent();
                    }}>
                    Resend code
                  </Button>
                </p>
              )}
            </Form.Group>
            <Form.Group>
              <Button
                type="submit"
                variant="primary"
                block
                disabled={!entryComplete}>
                Submit
              </Button>
            </Form.Group>
          </Form>
          <div>
            <div className="or-panel">
              <hr />
              <span className="or-text">or</span>
              <hr />
            </div>

            <p className="mb-2">
              <Button
                variant="link"
                onClick={() => {
                  super.changeState('signIn');
                  this.resetInputAndValidationInputComponent();
                }}>
                Go back and try a different email address
              </Button>
            </p>
            <p className="mb-0">
              Still no email?{' '}
              <a
                href="https://kb.loop.homes/i-cant-verify-my-email?utm_source=loop&utm_medium=webapp"
                target="_blank"
                rel="noreferrer">
                Go to Loop Support
              </a>
            </p>
          </div>
        </LoginCard>
      </LoginLayout>
    );
  }
}
