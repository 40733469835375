import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { SpacerHr } from '../../ui/Styled';
import infoSvg from '../../assets/svg/info.svg';
import InfoModal from '../../ui/Modal/InfoModal';
import { OnboardingContext } from '../../context/OnboardingContext';
import { getCurrentUserInfo } from '../../utils/auth';
import LoopSpinner from '../../ui/LoopSpinner';

const InfoDiv = styled.div`
  cursor: pointer;
  padding-top: 1px;
`;

const LabelDiv = styled.div`
  padding-top: 3px;
  font-size: 14px;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const IntroductionContainer = () => {
  const history = useHistory();
  const [showInfo, setShowInfo] = useState(false);
  const [infoContent, setInfoContent] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const { setProgressPercentage } = useContext(OnboardingContext);

  const infoItems = [
    {
      label: 'Your address',
      info: (
        <>
          <p>
            <strong>Loop Optimise</strong> uses your address to forecast solar
            generation at your location.
          </p>
          <p>
            Different locations will get different levels of sun based on
            position and the local weather. Optimise uses your address to create
            bespoke generation forecasts for your home.
          </p>
        </>
      ),
    },
    {
      label: 'Your tariff details',
      info: (
        <>
          <p>
            <strong>Loop Optimise</strong> imports from the grid when
            electricity is cheap and exports when electricity is expensive.
          </p>
          <p>
            Your tariff provides Optimise with your prices, allowing us to
            tailor our approach accordingly.
          </p>
        </>
      ),
    },
    {
      label: 'Your solar and battery details',
      info: (
        <p>
          <strong>Loop Optimise</strong> connects to your home energy system so
          we need to ask you for some details in order to connect.
        </p>
      ),
    },
  ];

  useEffect(() => {
    const asyncUseEffect = async () => {
      setProgressPercentage(0);
      const { attributes } = await getCurrentUserInfo();
      const isOnboarded = attributes['custom:is_onboarded'];
      if (isOnboarded) {
        history.push('/app/today');
      } else {
        setLoading(false);
      }
    };
    asyncUseEffect();
  }, []);

  const showInfoModal = info => {
    setInfoContent(info);
    setShowInfo(true);
  };

  const InfoRow = ({ label, info }) => (
    <div onClick={() => showInfoModal(info)}>
      <SpacerHr block />
      <div className="d-flex justify-content-between align-items-center mb-3">
        <LabelDiv>{label}</LabelDiv>
        <InfoDiv>
          <img src={infoSvg} alt="Info" />
        </InfoDiv>
      </div>
    </div>
  );

  return loading ? (
    <LoopSpinner />
  ) : (
    <>
      <InfoModal
        show={showInfo}
        onClose={() => setShowInfo(false)}
        infoContent={infoContent}
      />
      <h1 className="mb-3">Connect Loop to your battery</h1>
      <p>
        <strong>Loop</strong> takes your privacy seriously. We need to make sure
        we connect to the right battery and that you're authorised to access the
        data. We collect the following:
      </p>
      {infoItems.map(item => (
        <InfoRow {...item} />
      ))}
      <SpacerHr block />
      <Button onClick={() => history.push('/onboarding/address')}>
        Get started
      </Button>
    </>
  );
};

export default IntroductionContainer;
