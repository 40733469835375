import { useContext, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import cx from 'classnames';
import { Field, Formik } from 'formik';
import { SpacerHr } from '../../ui/Styled';
import { AppContext } from '../../context/AppContext';
import ErrorLabel from '../../ui/ErrorLabel';
import { getUserDetails } from '../../api/Optimise';
import InfoModal from '../../ui/Modal/InfoModal';

const StyledCard = styled(Card)`
  max-width: 480px !important;
  padding: 30px;
  text-align: center;

  @media (min-width: 576px) {
    padding: 40px;
  }

  h1 {
    margin-bottom: 16px;
  }
`;

const StyledDiv = styled.div`
  text-align: left;
`;

const ContinueButton = styled(Button)`
  margin: 0 0 12px;
`;

const SignOutButton = styled(Button)`
  width: 160px;
  margin: 0 auto;
`;

const validationSchema = Yup.object().shape({
  userSub: Yup.string()
    .required('Please enter the user sub id')
    .matches(
      /^[0-9a-f]{8}-[0-9a-f]{4}-70[0-9a-f]{2}-[0-9a-f]{4}-[0-9a-f]{12}$/i,
      'Please enter a valid UUID v4 sub',
    ),
});

const AdminContainer = () => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const { userSub, setUserSub } = useContext(AppContext);

  const onSubmit = async ({ userSub }) => {
    setError(false);
    await getUserDetails(userSub)
      .then(userDetails => {
        setUserSub(userSub);
        history.push('/app');
      })
      .catch(error => {
        console.log(error);
        return setError(true);
      });
    return true;
  };

  return (
    <StyledCard>
      <InfoModal
        show={error}
        onClose={() => setError(false)}
        infoContent="Something went wrong - please try again."
      />
      <h1>
        Welcome To
        <br />
        Loop Optimise
      </h1>
      <p>
        You’re signed in as an <strong>Admin user</strong>. Enter a user sub to
        view their data.
      </p>
      <StyledDiv>
        <Formik
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          initialValues={{
            userSub,
          }}>
          {({ handleSubmit, isValid, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Field name="userSub">
                {({ field, meta }) => (
                  <Form.Group controlId="userSub" className="text-left">
                    <Form.Label>User sub</Form.Label>
                    <Form.Control
                      type="text"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      isValid={!meta.error && meta.touched}
                      placeholder="User sub"
                      className={cx({ error: !!meta.error })}
                    />
                    {meta.error && meta.touched && (
                      <ErrorLabel label={meta.error} />
                    )}
                  </Form.Group>
                )}
              </Field>
              <ContinueButton
                variant="primary"
                disabled={!isValid || isSubmitting}
                block
                type="submit">
                Continue
              </ContinueButton>
            </Form>
          )}
        </Formik>
      </StyledDiv>
      <SpacerHr label="or" block />
      <SignOutButton variant="secondary">Sign out</SignOutButton>
    </StyledCard>
  );
};

export default AdminContainer;
