import errorDisc from '../assets/svg/disc/error-disc.svg';
import { contentfulPropTypes } from '../propTypes';

const GeneralError = ({ contentfulTag, contentType, error }) => (
  <div>
    <div className="text-center mb-3">
      <img src={errorDisc} alt="Error disc" />
    </div>
    <h1 className="mb-3">This service is temporarily unavailable</h1>
    <p>
      Sorry, Loop’s services are temporarily down and we aren’t able to complete
      your smart meter registration at this time. We’re looking into the reasons
      for this. Typically our services are back up quickly. Please come back and
      try again later.
    </p>
    {error && console.error(error)}
    {contentfulTag && console.log(contentfulTag, contentType)}
  </div>
);

GeneralError.propTypes = {
  ...contentfulPropTypes,
};

export default GeneralError;
