import { createContext, useState } from 'react';
import { usePersistedState } from '../hooks';
import PropTypes from 'prop-types';
import moment from 'moment';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = usePersistedState(
    'pageTitle',
    'Loop Optimise',
  );
  const [userSub, setUserSub] = usePersistedState('userSub', undefined);

  const [selectedDate, setSelectedDate] = useState(
    moment().subtract(1, 'days').toDate(),
  );
  const [savingsLoading, setSavingsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(undefined);
  const [isAdmin, setIsAdmin] = useState(false);
  const [analyticsStartDate, setAnalyticsStartDate] = usePersistedState(
    'analyticsStartDate',
    null,
  );
  const [datePickerWarning, setDatePickerWarning] = useState(null);

  const state = {
    pageTitle,
    setPageTitle,
    selectedDate,
    setSelectedDate,
    savingsLoading,
    setSavingsLoading,
    userDetails,
    setUserDetails,
    userSub,
    setUserSub,
    analyticsStartDate,
    setAnalyticsStartDate,
    isAdmin,
    setIsAdmin,
    datePickerWarning,
    setDatePickerWarning,
  };

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppContext, AppProvider };
