import moment from 'moment';

const formatDate = date => {
  const formattedDate = moment(date).format('DD/MM/YYYY');
  const now = moment().startOf('day');
  const targetDate = moment(date).startOf('day');

  const dayDifference = now.diff(targetDate, 'days');

  if (dayDifference === 0) {
    return `${formattedDate} (Today)`;
  } else if (dayDifference === 1) {
    return `${formattedDate} (Yesterday)`;
  } else if (dayDifference > 1 && dayDifference <= 7) {
    return `${formattedDate} (${dayDifference} days ago)`;
  } else {
    return formattedDate;
  }
};

export default formatDate;
