import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import LoopSpinner from '../../ui/LoopSpinner';
import { onboardingPropTypes } from '../../propTypes';
import { OnboardingContext } from '../../context/OnboardingContext';
import { RadioWrapper } from '../../ui/Styled';
import { createUserTrace } from '../../api/Optimise';
import yesNoToBool from '../../utils/yesNoToBool';

const ElectricVehicleContainer = () => {
  const history = useHistory();
  const { hasEv, setHasEv, setProgressPercentage } =
    useContext(OnboardingContext);
  const [touched, setTouched] = useState(!!hasEv);

  const validationSchema = Yup.object({
    hasEv: Yup.mixed().oneOf(['yes', 'no']).required(),
  });

  const onSubmit = ({ hasEv }) => {
    setHasEv(hasEv);
    createUserTrace('Electric Vehicle', {
      additional_information: {
        has_ev: yesNoToBool(hasEv),
      },
    });
    history.push('/onboarding/equipment-details');
  };

  useEffect(() => {
    setProgressPercentage(60);
  }, []);

  return (
    <>
      <h1 className="mb-3">Do you have an electric vehicle?</h1>
      <div>
        <>
          <p>
            If you have one or more electric vehicle, please let us know here.
          </p>
          <div className="text-left mt-5">
            <Formik
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              initialValues={{
                hasEv,
              }}
              validateOnChange>
              {({ handleSubmit, isValid, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Field name="hasEv">
                    {({ field, meta }) => (
                      <RadioWrapper
                        className={cx({
                          selected: field.value === 'yes',
                        })}>
                        <Form.Check
                          type="radio"
                          name={field.name}
                          id="more-less-than"
                          value={'yes'}
                          label="Yes, I have at least one electric vehicle"
                          onChange={e => {
                            setTouched(true);
                            field.onChange(e);
                          }}
                          onBlur={field.onBlur}
                          className={cx({ error: !!meta.error })}
                        />
                      </RadioWrapper>
                    )}
                  </Field>
                  <Field name="hasEv">
                    {({ field, meta }) => (
                      <RadioWrapper
                        className={cx({
                          selected: field.value === 'no',
                        })}>
                        <Form.Check
                          type="radio"
                          name={field.name}
                          id="radio-less-than"
                          value={'no'}
                          label="No, I don’t have an electric vehicle"
                          onChange={e => {
                            setTouched(true);
                            field.onChange(e);
                          }}
                          onBlur={field.onBlur}
                          className={cx({ error: !!meta.error })}
                        />
                      </RadioWrapper>
                    )}
                  </Field>
                  <Form.Group>
                    {!isSubmitting ? (
                      <Button
                        variant="primary"
                        disabled={!isValid || isSubmitting || !touched}
                        block
                        type="submit">
                        Continue
                      </Button>
                    ) : (
                      <LoopSpinner />
                    )}
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </div>
        </>
      </div>
    </>
  );
};

ElectricVehicleContainer.propTypes = {
  ...onboardingPropTypes,
};

export default ElectricVehicleContainer;
