import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { OnboardingContext } from '../../context/OnboardingContext';
import LoopSpinner from '../../ui/LoopSpinner';
import useSetUserDetails from '../../hooks/useSetUserDetails';

const ConnectingContainer = () => {
  const history = useHistory();
  const { triggerSetUserDetails, requestError } = useSetUserDetails();
  const { setProgressPercentage } = useContext(OnboardingContext);
  useEffect(() => {
    const asyncUseEffect = async () => {
      await triggerSetUserDetails();
      setProgressPercentage(0);
    };
    asyncUseEffect();
  }, []);

  return (
    <>
      <h1 className="mb-3">Connecting to your system</h1>
      {!requestError ? (
        <>
          <div className="mt-1 mb-4">
            <LoopSpinner />
          </div>
          <p className="mb-0">
            Please wait a moment while we attempt to connect to your inverter.
          </p>
          <Button
            disabled={true}
            onClick={() => history.push('/onboarding/connected')}>
            Continue
          </Button>
        </>
      ) : (
        <p className="mb-0">
          We encountered an error and were not able to complete your onboarding.
          Please try again and contact us if the problem persists.
        </p>
      )}
    </>
  );
};

export default ConnectingContainer;
