import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import TagManager from 'react-gtm-module';
import { ContentfulClient, ContentfulProvider } from 'react-contentful';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './index.css';
import App from './App';
import awsconfig from './config/aws-exports.js';
import contentfulConfig from './config/contentful';
import gtmConfig from './config/gtm';

const contentfulClient = new ContentfulClient(contentfulConfig);

Amplify.configure({
  Auth: {
    region: awsconfig.aws_project_region,
    userPoolId: awsconfig.aws_user_pools_id,
    userPoolWebClientId: awsconfig.aws_user_pools_web_client_id,
    oauth: {
      domain: 'lo-preview.auth.eu-west-1.amazoncognito.com', // replace with your Cognito domain
      scope: ['email', 'openid'],
      redirectSignIn: 'http://localhost:3000/sign-in', // replace with your redirect URL
      redirectSignOut: 'http://localhost:3000/sign-out', // replace with your signout URL
      responseType: 'code',
    },
  },
});

TagManager.initialize({ gtmId: gtmConfig.id });

ReactDOM.render(
  <React.StrictMode>
    <ContentfulProvider client={contentfulClient} locale="en-GB">
      <Router>
        <Switch>
          <Route component={App} />
        </Switch>
      </Router>
    </ContentfulProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
