import InformationPanel from './InformationPanel';
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { AppContext } from '../../../context/AppContext';
import { updateUserDetails } from '../../../api/Optimise';
import BasicSpinner from '../../BasicSpinner';
import { primary, white } from '../../../theme/colors';

const SimulationModeSettings = ({ loadUser, setShowError }) => {
  const { userDetails, userSub } = useContext(AppContext);
  const [switchOn, setSwitchOn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!!userDetails) {
      setSwitchOn(userDetails?.simulation_mode);
      setIsLoading(false);
    }
  }, [userDetails]);

  const handleSwitchChange = async () => {
    const invertedSwitch = !switchOn;
    setSwitchOn(invertedSwitch);
    setIsSaving(true);
    updateUserDetails(
      {
        battery_soc_lower_limit: userDetails.battery_details.soc_lower_limit,
        battery_soc_upper_limit: 100,
        simulation_mode: invertedSwitch,
      },
      userSub,
    )
      .then(() => {
        setIsSaving(false);
        loadUser();
      })
      .catch(e => {
        console.log(e);
        setIsSaving(false);
        setShowError(true);
      });
  };

  const StyledSwitch = styled(Form.Check)`
    margin-top: 6px;
    margin-left: 35px;
    .custom-control-input {
      &:checked ~ .custom-control-label::before {
        background-color: ${primary};
        border-color: ${primary};
      }
    }

    .custom-control-input:disabled:checked ~ .custom-control-label::before {
      background-color: rgb(255, 0, 133, 0.5);
    }

    .custom-control-label::before {
      background-color: ${white};
      transition: background-color 0.2s ease-in-out;
    }

    .custom-switch .custom-control-label::after {
      background-color: white;
    }
  `;

  const SpinnerWrapper = styled.div`
    position: relative;
    top: 1px;
  `;

  return (
    <InformationPanel title="Simulation mode">
      <li>
        Turning simulation mode on stops Loop Optimise controlling your battery.
        You will see recommended actions on "Today" but these will not be sent.
        <div className="d-flex align-items-end">
          <StyledSwitch
            type="switch"
            id="simulation-mode-switch"
            className="custom-switch"
            label="Simulation Mode"
            checked={switchOn}
            onChange={handleSwitchChange}
            disabled={isLoading || isSaving}
          />
          {isLoading ||
            (isSaving && (
              <SpinnerWrapper>
                <BasicSpinner />
              </SpinnerWrapper>
            ))}
        </div>
      </li>
    </InformationPanel>
  );
};

export default SimulationModeSettings;
