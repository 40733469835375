import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import LoopSpinner from '../../ui/LoopSpinner';
import { onboardingPropTypes } from '../../propTypes';
import { OnboardingContext } from '../../context/OnboardingContext';
import { RadioWrapper } from '../../ui/Styled';
import yesNoToBool from '../../utils/yesNoToBool';
import { createUserTrace } from '../../api/Optimise';

const SolarDiverterContainer = () => {
  const history = useHistory();
  const { setProgressPercentage, hasSolarDiverter, setHasSolarDiverter } =
    useContext(OnboardingContext);
  const [touched, setTouched] = useState(!!hasSolarDiverter);

  const validationSchema = Yup.object({
    hasSolarDiverter: Yup.mixed().oneOf(['yes', 'no']).required(),
  });

  const onSubmit = ({ hasSolarDiverter }) => {
    setHasSolarDiverter(hasSolarDiverter);
    createUserTrace('Solar Diverter', {
      additional_information: {
        has_solar_diverter: yesNoToBool(hasSolarDiverter),
      },
    });
    if (yesNoToBool(hasSolarDiverter)) {
      history.push('/onboarding/solar-diverter-warning');
    } else {
      history.push('/onboarding/electric-vehicle');
    }
  };

  useEffect(() => {
    setProgressPercentage(50);
  }, []);

  return (
    <>
      <h1 className="mb-3">Do you have a solar diverter? </h1>
      <div>
        <p>
          A solar diverter uses excess solar to either heat hot water or charge
          an electric vehicle.
        </p>
        <p>
          <a href="https://kb.loop.homes/why-cant-loop-optimise-be-used-with-a-solar-diverter">
            More info about solar diverters
          </a>
        </p>
        <div className="text-left mt-4">
          <Formik
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{
              hasSolarDiverter,
            }}
            validateOnChange>
            {({ handleSubmit, isValid, isSubmitting, values }) => (
              <Form onSubmit={handleSubmit}>
                <Field name="hasSolarDiverter">
                  {({ field, meta }) => (
                    <RadioWrapper
                      className={cx({
                        selected: field.value === 'yes',
                      })}>
                      <Form.Check
                        type="radio"
                        name={field.name}
                        id="more-less-than"
                        value={'yes'}
                        label="Yes, I have a solar diverter"
                        onChange={e => {
                          setTouched(true);
                          field.onChange(e);
                        }}
                        onBlur={field.onBlur}
                        className={cx({ error: !!meta.error })}
                      />
                    </RadioWrapper>
                  )}
                </Field>
                <Field name="hasSolarDiverter">
                  {({ field, meta }) => (
                    <RadioWrapper
                      className={cx({
                        selected: field.value === 'no',
                      })}>
                      <Form.Check
                        type="radio"
                        name={field.name}
                        id="radio-less-than"
                        value={'no'}
                        label="No, I don’t have a solar diverter"
                        onChange={e => {
                          setTouched(true);
                          field.onChange(e);
                        }}
                        onBlur={field.onBlur}
                        className={cx({ error: !!meta.error })}
                      />
                    </RadioWrapper>
                  )}
                </Field>
                {!isSubmitting ? (
                  <Button
                    variant="primary"
                    disabled={!isValid || isSubmitting || !touched}
                    block
                    type="submit">
                    Continue
                  </Button>
                ) : (
                  <LoopSpinner />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

SolarDiverterContainer.propTypes = {
  ...onboardingPropTypes,
};

export default SolarDiverterContainer;
