import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import cx from 'classnames';
import LoopSpinner from '../../ui/LoopSpinner';
import { onboardingPropTypes } from '../../propTypes';
import { OnboardingContext } from '../../context/OnboardingContext';
import { RadioWrapper } from '../../ui/Styled';
import { createUserTrace } from '../../api/Optimise';
import yesNoToBool from '../../utils/yesNoToBool';

const ExportLimitContainer = () => {
  const history = useHistory();
  const {
    hasExportLimit,
    inverterManufacturer,
    setHasExportLimit,
    setProgressPercentage,
  } = useContext(OnboardingContext);
  const [touched, setTouched] = useState(!!hasExportLimit);

  const validationSchema = Yup.object({
    hasExportLimit: Yup.mixed().oneOf(['yes', 'no']).required(),
  });

  const onSubmit = ({ hasExportLimit }) => {
    setHasExportLimit(hasExportLimit);
    createUserTrace('Export limit', {
      hasExportLimit: yesNoToBool(hasExportLimit),
    });
    history.push(`/onboarding/connection/${inverterManufacturer}`);
  };

  useEffect(() => {
    setProgressPercentage(80);
  }, []);

  return (
    <>
      <h1 className="mb-3">Do you have G99 export certificate?</h1>
      <div>
        <>
          <p>
            Have you applied to you local grid operator (known as your DNO) to
            increase the limit on the rate at which you export?
          </p>
          <div className="text-left mt-5">
            <Formik
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              initialValues={{
                hasExportLimit,
              }}
              validateOnChange>
              {({ handleSubmit, isValid, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Field name="hasExportLimit">
                    {({ field, meta }) => (
                      <RadioWrapper
                        className={cx({
                          selected: field.value === 'no',
                        })}>
                        <Form.Check
                          type="radio"
                          name={field.name}
                          id="more-less-than"
                          value={'no'}
                          label="Yes, I have a G99 certificate - my export rate isn’t limited"
                          onChange={e => {
                            setTouched(true);
                            field.onChange(e);
                          }}
                          onBlur={field.onBlur}
                          className={cx({ error: !!meta.error })}
                        />
                      </RadioWrapper>
                    )}
                  </Field>
                  <Field name="hasExportLimit">
                    {({ field, meta }) => (
                      <RadioWrapper
                        className={cx({
                          selected: field.value === 'yes',
                        })}>
                        <Form.Check
                          type="radio"
                          name={field.name}
                          id="radio-less-than"
                          value={'yes'}
                          label="No, I don’t have a G99 export certificate - my export rate is limited to 3.6kW"
                          onChange={e => {
                            setTouched(true);
                            field.onChange(e);
                          }}
                          onBlur={field.onBlur}
                          className={cx({ error: !!meta.error })}
                        />
                      </RadioWrapper>
                    )}
                  </Field>
                  <Form.Group>
                    {!isSubmitting ? (
                      <Button
                        variant="primary"
                        disabled={!isValid || isSubmitting || !touched}
                        block
                        type="submit">
                        Continue
                      </Button>
                    ) : (
                      <LoopSpinner />
                    )}
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </div>
        </>
      </div>
    </>
  );
};

ExportLimitContainer.propTypes = {
  ...onboardingPropTypes,
};

export default ExportLimitContainer;
