import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../../../context/AppContext';
import { getTariffKeyInfo } from '../../../utils/tariff';
import textOrPlaceholder from '../../../utils/textOrPlaceholder';

const Container = styled.div`
  margin-top: 24px;
  width: 100%;
  border-radius: 8px;
  padding: 20px;
  background-image: linear-gradient(to right, #2648b2, #473eac);
`;

const Title = styled.h3`
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px;
  letter-spacing: 1.5px;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const TableContainer = styled.div`
  background-color: white;
`;

const TariffNameBanner = styled.div`
  background-color: #eff2f6;
  color: #0c1f28;
  font-size: 14px;
  font-weight: bold;
  padding: 12px 20px;
  text-align: center;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
`;

const Th = styled.th`
  padding: 20px 10px 10px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: ${props => props.color};
  border-bottom: 1px solid #afb6b9;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const Td = styled.td`
  padding: 15px;
  border: none;
  font-size: 12px;
  text-align: ${props => (props.center ? 'center' : 'left')};
  border-bottom: 1px solid #afb6b9;

  tr:last-child & {
    border-bottom: none;
  }

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const RateLabel = styled.span`
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background-color: ${props => props.bgColor};
  border: 1px solid ${props => props.borderColor ?? '#fff'};
  color: ${props => props.color ?? '#0c1f28'};
  padding: 4px 8px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: bold;
  font-size: 10px;

  @media (min-width: 576px) {
    font-size: 12px;
  }
`;

const RateDescription = styled.p`
  margin-top: 8px;
  font-size: 12px;

  @media (min-width: 576px) {
    font-size: 12px;
  }
`;

const formatTariffName = name => {
  name = name.replaceAll('-', ' ');
  name = name.replace('|', ': ');
  name = name
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  return name;
};

const getRowContent = key => {
  switch (key) {
    case 'day':
      return {
        heading: 'Day Rate',
        description: 'Average cost electricity.',
        color: '#0c1f28',
        bgColor: '#fff',
        borderColor: '#0c1f28',
      };
    case 'cheap':
      return {
        heading: 'Cheap Rate',
        description:
          'Charge the battery when the import rate is at its lowest.',
        color: '#fff',
        bgColor: '#3cba92',
        borderColor: '#3cba92',
      };
    case 'peak':
      return {
        heading: 'Peak Rate',
        description:
          'Consume power from the battery when the import rate is highest for biggest savings.',
        color: '#0c1f28',
        bgColor: '#f7d22d',
        borderColor: '#f7d22d',
      };
  }
};

const TariffKey = ({
  peakTariffImportRate,
  middleTariffImportRate,
  cheapTariffImportRate,
  peakTariffExportRate,
  middleTariffExportRate,
  cheapTariffExportRate,
  hasPeak,
}) => {
  const [tariffKeyInfo, setTariffKeyInfo] = useState(undefined);
  const { userDetails } = useContext(AppContext);
  useEffect(() => {
    if (
      !!userDetails?.tariff_details &&
      peakTariffImportRate &&
      cheapTariffImportRate &&
      peakTariffExportRate &&
      cheapTariffExportRate
    ) {
      const tariffKeyInfo = getTariffKeyInfo(
        peakTariffImportRate,
        cheapTariffImportRate,
        peakTariffExportRate,
        cheapTariffExportRate,
        userDetails?.tariff_details?.tariff_name,
        middleTariffImportRate,
        middleTariffExportRate,
        hasPeak,
      );
      setTariffKeyInfo(tariffKeyInfo);
    } else {
      setTariffKeyInfo({
        day: { import: undefined, export: undefined },
        cheap: { import: undefined, export: undefined },
      });
    }
  }, [
    userDetails,
    peakTariffImportRate,
    cheapTariffImportRate,
    peakTariffExportRate,
    cheapTariffExportRate,
  ]);

  return (
    <Container>
      <Title>Tariff Rates Explained</Title>
      <TableContainer>
        {userDetails?.tariff_details?.tariff_name && (
          <TariffNameBanner>
            {formatTariffName(userDetails.tariff_details.tariff_name)}
          </TariffNameBanner>
        )}
        <Table>
          <thead>
            <tr>
              <Th></Th>
              <Th color="#ff0085">Import</Th>
              <Th color="#2648b2">Export</Th>
            </tr>
          </thead>
          <tbody>
            {tariffKeyInfo &&
              Object.entries(tariffKeyInfo)
                .reverse()
                .map((entry, index) => {
                  const [key, value] = entry;
                  const content = getRowContent(key);
                  return (
                    value && (
                      <tr key={index}>
                        <Td>
                          <RateLabel
                            color={content.color}
                            bgColor={content.bgColor}
                            borderColor={content.borderColor}>
                            {content.heading}
                          </RateLabel>
                          <RateDescription>
                            {content.description}
                          </RateDescription>
                        </Td>
                        <Td center>
                          {textOrPlaceholder(value.import, value.import)}
                        </Td>
                        <Td center>
                          {textOrPlaceholder(value.export, value.export)}
                        </Td>
                      </tr>
                    )
                  );
                })}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default TariffKey;
