import * as Yup from 'yup';
import styled from 'styled-components';
import BaseConnectionContainer from './BaseConnectionContainer';
import usernameLocation from '../../../assets/images/growatt-username-location.png';
import appUsernameLocation from '../../../assets/images/growatt-username-location-app.png';

const GrowattConnectionContainer = () => {
  const fields = [
    {
      name: 'api_username',
      label: 'Growatt username',
      validation: Yup.string()
        .required('Please enter your Growatt username')
        .max(200, 'Username must be 200 characters or less'),
    },
    {
      name: 'api_password',
      label: 'Growatt password',
      type: 'password',
      validation: Yup.string()
        .required('Please enter your Growatt password')
        .max(200, 'Password must be 200 characters or less'),
    },
  ];

  const StyledModalSection = styled.div`
    margin-top: 2rem;
  `;

  const StyledImg = styled.img`
    width: 100%;
    margin: 0 auto;
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
  `;

  return (
    <BaseConnectionContainer
      fields={fields}
      inverterManufacturer="Growatt"
      exampleModalContent={
        <>
          <div>
            <h4>
              Find your <strong>Username</strong>:
            </h4>
            <ul>
              <li>
                Growatt users will have been given a login by their installer
                for either an app (ShinePhone) or a{' '}
                <a
                  href="https://server.growatt.com/login"
                  target="_blank"
                  rel="noreferrer">
                  website
                </a>
              </li>
              <li>
                If you are logged into the app or the website, you will be able
                to find your username, and you can choose to reset your password
              </li>
              <li>
                If you’re not logged in and don’t know your username and
                password, please contact your installer.
              </li>
              <li>Username location on the website:</li>
            </ul>
            <StyledImg
              src={usernameLocation}
              alt="Username location on website"
            />
          </div>

          <StyledModalSection>
            <ul>
              <li>Username location on the app:</li>
            </ul>
            <StyledImg
              src={appUsernameLocation}
              alt="Username location in app"
            />
          </StyledModalSection>
        </>
      }
    />
  );
};

export default GrowattConnectionContainer;
