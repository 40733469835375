const parseAddress = delimited => {
  if (typeof delimited === 'string' && delimited) {
    const [
      flatNumber,
      houseNumber,
      houseName,
      address1,
      dependentLocality,
      town,
      county,
      postCode,
      building,
      doubleDependentLocality,
      dependentThorofare,
      meterCode,
      knownElectricityMeter,
      knownGasMeter,
      igtWarning,
    ] = delimited.split('|');
    return {
      id: delimited,
      flatNumber,
      houseNumber,
      houseName,
      address1,
      dependentLocality,
      town,
      county,
      postCode,
      building,
      doubleDependentLocality,
      dependentThorofare,
      meterCode,
      knownElectricityMeter,
      knownGasMeter,
      igtWarning,
    };
  }
  return null;
};

export const parseAddressForStripe = delimited => {
  const parsed = parseAddress(delimited);

  const addressLine1 = [];

  if (!!parsed?.flatNumber) {
    addressLine1.push(parsed.flatNumber);
  }
  if (!!parsed?.houseNumber) {
    addressLine1.push(parsed.houseNumber);
  }
  if (!!parsed?.houseName) {
    addressLine1.push(parsed.houseName);
  }
  if (!!parsed?.address1) {
    addressLine1.push(parsed.address1);
  }

  return {
    city: parsed?.town,
    country: 'GB',
    line1: addressLine1.join(', '),
    line2: parsed?.dependentLocality,
    postal_code: parsed?.postCode,
    state: parsed?.county,
  };
};

export const parseAddressString = delimited => {
  const address = parseAddressForStripe(delimited);

  return [
    address.line1,
    address.line2,
    address.city,
    address.state,
    address.postal_code,
  ]
    .join(', ')
    .replace(', , ', ', ');
};

export default parseAddress;
