import PropTypes from 'prop-types';

const onboardingPropTypes = {
  redirectUrl: PropTypes.string,
  setRedirectUrl: PropTypes.func,
  termsAndConditions: PropTypes.string,
  setTermsAndConditions: PropTypes.func,
  consentFlow: PropTypes.oneOf(['onboarding', 'reconfirm']),
  setConsentFlow: PropTypes.func,
  showAlert: PropTypes.bool,
  setShowAlert: PropTypes.func,
  alert: PropTypes.string,
  setAlert: PropTypes.func,
};
export default onboardingPropTypes;
