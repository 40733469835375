import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import styled from 'styled-components';
import cx from 'classnames';
import LoopSpinner from '../../ui/LoopSpinner';
import { onboardingPropTypes } from '../../propTypes';
import { OnboardingContext } from '../../context/OnboardingContext';
import { SpacerHr } from '../../ui/Styled';
import warningIcon from '../../assets/svg/warning-icon.svg';
import checkboxEmpty from '../../assets/svg/check-box-empty.svg';
import checkboxError from '../../assets/svg/check-box-error.svg';
import checkboxFilled from '../../assets/svg/check-box-filled.svg';
import ErrorLabel from '../../ui/ErrorLabel';

const StyledButton = styled(Button)`
  margin-top: 36px;
  text-decoration: none !important;
`;

const StyledCheckboxDiv = styled.div`
  margin-bottom: 10px;

  .form-check {
    padding-left: 0;
    background: url(${checkboxEmpty}) no-repeat top left;

    input {
      display: none;
    }
    .form-check-label {
      p {
        padding-left: 32px;
      }
    }
  }

  &.selected {
    .form-check {
      background-image: url(${checkboxFilled});
    }
  }
  &.error {
    .form-check {
      background-image: url(${checkboxError});
    }
  }

  .error-label {
    margin-left: 32px;
  }
`;

const StyledConfirmation = styled.div`
  margin-left: 32px;
  font-size: 14px;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const StyledImg = styled.img`
  @media (max-width: 575px) {
    width: 100%;
    max-width: 60px;
  }
`;

const SolarDiverterWarningContainer = () => {
  const history = useHistory();
  const [touched, setTouched] = useState(false);
  const { setProgressPercentage } = useContext(OnboardingContext);

  const validationSchema = Yup.object({
    confirm: Yup.boolean().required().oneOf([true]),
  });

  const onSubmit = () => {
    history.push('/onboarding/electric-vehicle');
  };

  useEffect(() => {
    setProgressPercentage(0);
  }, []);

  return (
    <>
      <div className="mb-3">
        <StyledImg src={warningIcon} alt="Warning icon" />
      </div>
      <h1 className="mb-3">Warning!</h1>
      <div>
        <p>
          <strong>
            Loop Optimise won’t work if you have a solar diverter running
          </strong>
        </p>
        <p>
          Loop Optimise exports electricity when export prices are high - but if
          a diverter is active, it intercepts any exported electricity and
          passes it to your EV/hot water, meaning you get no export payments.
          So, please either <strong>deactivate the diverter</strong> or{' '}
          <strong>don’t use Loop Optimise</strong>.
        </p>
        <div className="text-left mt-4">
          <Formik
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{
              confirm: false,
            }}
            validateOnChange>
            {({ handleSubmit, isValid, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                {!isSubmitting ? (
                  <>
                    <Field name="confirm">
                      {({ field, meta }) => (
                        <StyledCheckboxDiv
                          className={`text-left ${cx({
                            selected: field.value,
                            error: !!meta.error,
                          })}`}>
                          <Form.Check
                            type="checkbox"
                            name="confirm"
                            id="confirm-check"
                            onChange={e => {
                              setTouched(true);
                              return field.onChange(e);
                            }}
                            onBlur={field.onBlur}
                            className={cx({ error: !!meta.error })}
                            label={
                              <StyledConfirmation>
                                I confirm that I will deactivate my solar
                                diverter in order to use Loop Optimise.
                              </StyledConfirmation>
                            }
                          />
                          {meta.error && meta.touched && (
                            <ErrorLabel
                              pad={false}
                              showIcon={false}
                              label={meta.error}
                            />
                          )}
                        </StyledCheckboxDiv>
                      )}
                    </Field>
                    <Button
                      variant="primary"
                      disabled={!isValid || isSubmitting || !touched}
                      block
                      type="submit">
                      Continue using Loop Optimise
                    </Button>
                    <SpacerHr label="or" />
                    <StyledButton
                      variant="secondary"
                      block
                      href="https://kb.loop.homes/why-cant-loop-optimise-be-used-with-a-solar-diverter"
                      target="_blank"
                      rel="noreferrer">
                      Find out more
                    </StyledButton>
                  </>
                ) : (
                  <LoopSpinner />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

SolarDiverterWarningContainer.propTypes = {
  ...onboardingPropTypes,
};

export default SolarDiverterWarningContainer;
