import React, { createRef } from 'react';
import { ForgotPassword } from 'aws-amplify-react';
import LoginLayout from '../ui/Layout/LoginLayout';
import LoginCard from '../ui/LoginCard';
import { Button, Card, Form } from 'react-bootstrap';
import './styles.scss';
import VerificationInput from 'react-verification-input';

/**
 * Extending amplify boilerplate ForgotPassword screen.
 */
export default class LoopForgotPassword extends ForgotPassword {
  /**
   * Class constructor
   * @param {Object} props The screen's properties
   */
  constructor(props) {
    super(props);
    this._validAuthStates = ['forgotPassword'];
    this.onKeyUp = this.onKeyUp.bind(this);

    this.state = {
      resentEmail: false,
    };

    this.validationInputRef = createRef();
  }

  /**
   * onKeyUp event handler
   * @param {Object} event The event payload
   */
  onKeyUp(event) {
    if (event.charCode === 13) {
      event.preventDefault();
      this.state.delivery || this.state.username ? this.submit() : this.send();
    }
  }

  /**
   * Show send view form
   * @return {JSX.Element}
   */
  sendView() {
    const { loading } = this.state;
    return (
      <Form className="text-left">
        <Form.Group>
          <Card.Text>
            Enter the email address you used to register with Loop, and
            we&apos;ll send you instructions to reset your password.
          </Card.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="username">Email address</Form.Label>
          <Form.Control
            id="signin_username"
            key="username"
            name="username"
            onChange={e => {
              e.target.value = e.target.value.toLowerCase().trim();
              this.handleInputChange(e);
            }}
            onKeyPress={this.onKeyUp}
            type="email"
            placeholder="Email address"
          />
        </Form.Group>
        <Form.Group>
          <Button
            type="button"
            variant="primary"
            block
            disabled={loading}
            onClick={() => super.send()}>
            Send Instructions
          </Button>
        </Form.Group>
        <Form.Group className="mb-0">
          <p className="text-center mb-0 mt-3">
            <Button variant="link" onClick={() => super.changeState('signIn')}>
              Back to Sign In?{' '}
            </Button>
          </p>
        </Form.Group>
      </Form>
    );
  }

  /**
   * Show submit view form
   * @return {JSX.Element}
   */
  submitView() {
    const { loading, resentEmail } = this.state;
    return (
      <Form className="text-left">
        <Form.Group>
          <Card.Text>
            Please enter the code which we have emailed to you and create a new
            password that is at least 8 characters long.
          </Card.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="username">Code</Form.Label>
          <div>
            <VerificationInput
              ref={this.validationInputRef}
              validChars="0-9"
              placeholder="0"
              removeDefaultStyles={true}
              autoFocus={true}
              inputField={{
                name: 'code',
                onChange: this.handleInputChange,
              }}
              container={{
                className: 'verification-input-container',
              }}
              characters={{
                className: 'verification-input-characters',
              }}
              character={{
                className: 'verification-input-character',
                classNameInactive: 'verification-input-character--inactive',
                classNameSelected: 'verification-input-character--selected',
              }}
            />
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="username">New Password</Form.Label>
          <Form.Control
            id="forgot_password_new_password"
            key="password"
            name="password"
            onChange={this.handleInputChange}
            onKeyPress={this.onKeyUp}
            type="password"
            placeholder="New Password"
          />
        </Form.Group>
        <Form.Group>
          <Button
            type="button"
            variant="primary"
            block
            disabled={loading}
            onClick={() => super.submit()}>
            Reset Password
          </Button>
        </Form.Group>
        <Form.Group className="mb-0 mt-1">
          {resentEmail ? (
            <p className="text-center mb-0">Email resent with code</p>
          ) : (
            <p className="text-center mb-0 pt-1">
              Email didn&apos;t arrive?{' '}
              <Button
                variant="link"
                onClick={() => {
                  super.send();
                  this.setState({ resentEmail: true });

                  this.resetInputAndValidationInputComponent();
                }}>
                Resend Code{' '}
              </Button>
            </p>
          )}
          <p className="text-center mb-0 mt-3">
            <Button variant="link" onClick={() => super.changeState('signIn')}>
              Back to Sign In
            </Button>
          </p>
        </Form.Group>
      </Form>
    );
  }

  /**
   * Reset the input and validation
   */
  resetInputAndValidationInputComponent() {
    this.inputs.code = 0;
    this.validationInputRef.current.state.tan = '';
    this.validationInputRef.current.state.previousTan = '';
    this.validationInputRef.current.state.selectedIndex = 0;
    this.validationInputRef.current.state.isActive = false;
    this.validationInputRef.current.state.isValidTan = false;
  }

  /**
   * Show loop forgot password screen
   * @param {Object} theme The theme object
   * @return {JSX.Element}
   */
  showComponent(theme) {
    const { authData = {} } = this.props;
    return (
      <LoginLayout>
        <LoginCard>
          <Card.Title as="h1">Reset Password</Card.Title>
          {this.state.delivery || authData.username
            ? this.submitView()
            : this.sendView()}
        </LoginCard>
      </LoginLayout>
    );
  }
}
