import { useContext, useEffect } from 'react';
import { AppContext } from '../context/AppContext';

const useOnDateChange = (...callbacks) => {
  const { selectedDate } = useContext(AppContext);

  useEffect(() => {
    if (callbacks.length === 0) {
      throw new Error('useOnDateChange must be used with a callback');
    }

    callbacks.forEach(callback => {
      if (typeof callback !== 'function') {
        throw new Error(
          'All arguments passed to useOnDateChange must be functions',
        );
      }
      callback(selectedDate);
    });
  }, [selectedDate, ...callbacks]);
};

export default useOnDateChange;
