import { Button, Col, Container, Modal, Row } from 'react-bootstrap';

const InfoModal = ({
  show,
  onClose,
  infoContent,
  buttonLabel = 'Okay',
  size = 'md',
}) => {
  return (
    <Modal
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}>
      <Modal.Body className="pt-0">
        <Container fluid>
          <Row>
            <Col className="text-center">
              <div className="text-left pt-4 pb-2">{infoContent}</div>
              <div className="text-right mb-1">
                <Button variant="primary" onClick={onClose} className="px-5">
                  {buttonLabel}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default InfoModal;
