import { VerifyContact } from 'aws-amplify-react';
import { Auth } from '@aws-amplify/auth';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import VerificationInput from 'react-verification-input';
import LoginLayout from '../ui/Layout/LoginLayout';
import LoginCard from '../ui/LoginCard';

/**
 * Extending amplify boilerplate VerifyContact screen.
 */
export default class LoopVerifyContact extends VerifyContact {
  /**
   * Class constructor
   * @param {Object} props The screen's properties
   */
  constructor(props) {
    super(props);

    this._validAuthStates = ['verifyContact'];

    this.init = false;
    this.loaded = false;
  }

  /**
   * Submit event handler
   * @return {Promise<void>}
   */
  async submit() {
    const attr = 'email';
    const { code } = this.inputs;
    await Auth.verifyCurrentUserAttributeSubmit(attr, code)
      .then(() => {
        this.changeState('signedIn', this.props.authData);
        this.setState({ verifyAttr: null });
      })
      .catch(err => this.error(err));
  }

  /**
   * validate event handler
   * @param {Object} event The validate event payload
   */
  validate(event) {
    this.handleInputChange(event);
    const { value } = event.nativeEvent.target;
    if (value.length === 6) {
      this.submit().then(() => {});
    }
  }

  /**
   * Show component view method
   * @param {Object} theme The theme config object
   * @return {JSX.Element}
   */
  showComponent(theme) {
    // only run this once when the component first renders
    if (this.init === false) {
      this.init = true;

      Auth.verifyCurrentUserAttribute('email')
        .then(() => {
          this.loaded = true;
          this.setState({ verifyAttr: 'email' });
        })
        .catch(err => this.error(err));
    }

    return (
      <LoginLayout>
        <LoginCard>
          <Card.Title as="h1">Verify your contact details</Card.Title>
          <Card.Text>
            Your contact details changed recently. In order to allow account
            recovery later, we need to verify your contact information now.
          </Card.Text>
          {this.loaded ? (
            <Form
              onSubmit={e => {
                e.preventDefault();
                this.submit();
                return false;
              }}>
              <div>
                <VerificationInput
                  validChars="0-9"
                  placeholder="0"
                  removeDefaultStyles={true}
                  inputField={{
                    name: 'code',
                    onChange: event => this.validate(event),
                  }}
                  container={{
                    className: 'verification-input-container',
                  }}
                  characters={{
                    className: 'verification-input-characters',
                  }}
                  character={{
                    className: 'verification-input-character',
                    classNameInactive: 'verification-input-character--inactive',
                    classNameSelected: 'verification-input-character--selected',
                  }}
                />
              </div>
              <Button theme={theme} type="submit">
                Submit
              </Button>
            </Form>
          ) : (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
          <Form.Group className="mb-0 mt-4">
            <p className="text-center mb-0">
              Email didn&apos;t arrive?{' '}
              <Button variant="link" onClick={this.resend}>
                Resend email
              </Button>
            </p>
          </Form.Group>
        </LoginCard>
      </LoginLayout>
    );
  }
}
