import awsmobile from '../config/aws-exports.js';
import apiRequest from '../utils/apiRequest';

export const getAddressesByPostcode = async postcode => {
  const preppedPostcode = postcode.replace(' ', '').trim();
  const response = await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/geo-location/addresses/${preppedPostcode}`,
  );

  if (response.addresses.length === 0) {
    /**
     * TODO:
     * Replace the following if condition with one referencing the API response
     * once that differentiates between invalid postcodes and non-mainland ones
     */
    if (postcode.match(/^(BT|IM|GY|JE|HS|KA2(7|8)|KW|BF|ZE|PH|IV)/i)) {
      throw new Error(
        'Our tariff database only covers the UK mainland.\nPlease enter a mainland postcode to continue.',
      );
    } else {
      throw new Error('Could not find any homes for postcode: ' + postcode);
    }
  }

  return response;
};

export const getGeocodeForPostcode = async postcode =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/geo-location/geocode/${postcode}`,
  );

export const fakeRejectedRequest = async () => await new Promise().reject();

export const getRegion = async postcode =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/geo-location/distribution-id/${postcode}`,
  );
