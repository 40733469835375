import {
  ConfirmSignIn,
  RequireNewPassword,
  withAuthenticator,
} from 'aws-amplify-react';
import {
  LoopSignUp,
  LoopSignIn,
  LoopConfirmSignUp,
  LoopForgotPassword,
  LoopVerifyContact,
} from './auth';
import AppRouter from './navigation/AppRouter';
import 'bootstrap/dist/css/bootstrap.min.css';
import './theme/styles.scss';

/* eslint-disable react/jsx-key */
export default withAuthenticator(AppRouter, false, [
  <LoopSignIn />,
  <ConfirmSignIn />,
  <LoopVerifyContact />,
  <LoopSignUp />,
  <LoopConfirmSignUp />,
  <LoopForgotPassword />,
  <RequireNewPassword />,
]);
