import tariffs from '../data/tariffs.json';

function getTariffById(id) {
  return tariffs.find(tariff => tariff.id === id) || null;
}

const getTariffsForSupplier = supplierName => {
  const supplierTariffs = [];
  tariffs.forEach(tariff => {
    if (supplierName === tariff.supplier) {
      supplierTariffs.push(tariff);
    }
  });
  return supplierTariffs;
};

const getSupplierNames = () => {
  const supplierNames = [];
  tariffs.forEach(({ supplier }) => {
    if (!supplierNames.includes(supplier)) {
      supplierNames.push(supplier);
    }
  });
  return supplierNames;
};

export { getTariffById, getTariffsForSupplier, getSupplierNames };
