export default [
  {
    name: 'Today',
    path: '/app/today',
    active: window.location.pathname.includes('/app/today'),
  },
  {
    name: 'Savings',
    path: '/app/savings',
    active: window.location.pathname.includes('/app/savings'),
  },
  {
    name: 'Energy Flow',
    path: '/app/energy-flow',
    active: window.location.pathname.includes('/app/energy-flow'),
  },
  {
    name: 'Settings',
    path: '/app/settings',
    active: window.location.pathname.includes('/app/settings'),
  },
  {
    name: 'Help',
    path: '/app/help',
    active: window.location.pathname.includes('/app/help'),
  },
];
