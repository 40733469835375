import RecentBatteryStatus from '../../ui/Dashboard/Today/RecentBatteryStatus';
import TodaySoFar from '../../ui/Dashboard/Today/TodaySoFar';
import FurtherInfo from '../../ui/Dashboard/Today/FurtherInfo';
import withPageTitle from '../../hocs/withPageTitle';
import moment from 'moment';
import { useEffect, useContext, useState } from 'react';
import {
  getCurrentBehaviourForecast,
  getTwentyHoursOfSystemData,
  getUserDetails,
} from '../../api/Optimise';
import LoadingCard from '../../ui/LoadingCard';
import WarningPanel from '../../ui/WarningPanel';
import InfoModal from '../../ui/Modal/InfoModal';
import { AppContext } from '../../context/AppContext';
import aggregateRecords from '../../utils/aggregateRecords';

const TodayContainer = () => {
  const { setAnalyticsStartDate } = useContext(AppContext);
  const [twentyFourHoursOfData, setTwentyFourHoursOfData] = useState(undefined);
  const [latestRecord, setlatestRecord] = useState(undefined);
  const [behaviourForecast, setBehaviourForecast] = useState(undefined);
  const [lastInstruction, setLastInstruction] = useState(undefined);
  const [nextInstruction, setNextInstruction] = useState(undefined);
  const [behaviorChartData, setBehaviourChartData] = useState(undefined);
  const [historicChartData, setHistoricChartData] = useState(undefined);
  const [todaySoFarData, setTodaySoFarData] = useState(undefined);
  const [showNoDataModal, setShowNoDataModal] = useState(false);
  const [fetchingTwentyFourHoursOfData, setFetchingTwentyFourHoursOfData] =
    useState(false);

  const { userSub } = useContext(AppContext);

  useEffect(() => {
    const asyncUseEffect = async () => {
      setFetchingTwentyFourHoursOfData(true);
      try {
        const data = await getTwentyHoursOfSystemData(userSub);
        const latestRecord = data[data?.length - 1];
        setlatestRecord(latestRecord);
        setTwentyFourHoursOfData(data);
        setFetchingTwentyFourHoursOfData(false);
      } catch (error) {
        setFetchingTwentyFourHoursOfData(false);
        console.log(error);
      }

      try {
        const user = await getUserDetails(userSub);
        if (user?.analytics_data_range?.start_date) {
          setAnalyticsStartDate(user?.analytics_data_range?.start_date);
        } else {
          setAnalyticsStartDate(null);
        }
      } catch (error) {
        console.log(error);
        setAnalyticsStartDate(null);
      }
    };
    asyncUseEffect();
  }, []);

  useEffect(() => {
    const asyncUseEffect = async () => {
      const data = await getCurrentBehaviourForecast(userSub);
      const timeThreshold = moment();

      const futureRecords = data?.filter(record => {
        const recordTime = moment
          .utc(record.target_datetime_utc * 1000)
          .local();
        return recordTime.isAfter(timeThreshold);
      });

      setBehaviourForecast(futureRecords);
      if (futureRecords?.length) {
        setLastInstruction(data[0]);
        setNextInstruction(data[1]);
      }
    };
    asyncUseEffect();
  }, []);

  useEffect(() => {
    if (!!twentyFourHoursOfData && !!behaviourForecast) {
      const today = moment().startOf('day');

      // build data for chart
      const behaviourForecastForToday = behaviourForecast?.filter(row => {
        const itemDate = moment.utc(row.target_datetime_utc * 1000);
        return itemDate.isSame(today, 'day');
      });

      const futureChartData = behaviourForecastForToday?.map(row => ({
        data: row,
        x: moment
          .utc(row.target_datetime_utc * 1000)
          .local()
          .format('HH:mm'),
        y:
          row.optimal_target_soc_original <= 100
            ? row.optimal_target_soc_original
            : 100,
      }));

      const dataForToday = twentyFourHoursOfData?.filter(row => {
        const itemDate = moment.unix(row.end_date);
        return itemDate.isSame(today, 'day');
      });

      const historicChartData = dataForToday
        ?.filter(row => row.battery_soc !== null) // Filter out rows with null battery_soc
        .map(row => ({
          data: row,
          x: moment.unix(row.end_date).format('HH:mm'),
          y: row.battery_soc <= 100 ? row.battery_soc : 100,
        }));

      const behaviorChartRawData = [...historicChartData, ...futureChartData];

      // de-depupe the chart array
      const uniqueBehaviorChartData = behaviorChartRawData.filter(
        (obj, index, self) => index === self.findIndex(o => o.x === obj.x),
      );

      dataForToday.forEach(row => {});

      setTodaySoFarData(aggregateRecords(dataForToday));
      setHistoricChartData(historicChartData);
      setBehaviourChartData(uniqueBehaviorChartData);
    }
  }, [twentyFourHoursOfData, behaviourForecast]);

  const intersectionX =
    historicChartData && historicChartData[historicChartData?.length - 1].x;
  const intersectionY =
    (behaviorChartData &&
      behaviorChartData.find(d => d.x === intersectionX)?.y) ||
    0;

  const notEnoughData = fetchingTwentyFourHoursOfData || !twentyFourHoursOfData;

  return !fetchingTwentyFourHoursOfData ? (
    <div>
      {notEnoughData && (
        <>
          <WarningPanel
            title="Not enough data to show Today's behaviour."
            body="Please wait 30 minutes for the Loop Optimise calculations to be made."
            linkText="Why does Loop Optimise not have my data yet?"
            linkAction={() => setShowNoDataModal(true)}
          />

          <InfoModal
            show={showNoDataModal}
            onClose={() => setShowNoDataModal(false)}
            infoContent={
              <div>
                Loop Optimise processes a variety of data sources to determine
                actions that affect your battery's state of charge. These data
                sources update at different intervals—every 5, 15, and 30
                minutes. As a result, new actions are only taken once all
                updated data has been gathered, which is why Loop Optimise
                operates on the hour and half-hour.
                <br />
                <br />
                So, when you first sign up, you may need to wait up to 30
                minutes for any information to be available.
              </div>
            }
          />
        </>
      )}
      <RecentBatteryStatus
        latestRecord={latestRecord}
        lastInstruction={lastInstruction}
        nextInstruction={nextInstruction}
      />
      <TodaySoFar todaySoFarData={todaySoFarData} />
      <FurtherInfo
        intersectionX={intersectionX}
        intersectionY={intersectionY}
        historicChartData={historicChartData}
        behaviorChartData={behaviorChartData}
      />
    </div>
  ) : (
    <div className="pt-3">
      <LoadingCard />
    </div>
  );
};

export default withPageTitle('Your Optimisation', TodayContainer);
