import parseAddress from './parseAddress';

const getAddressFromId = addressId => {
  const parsedAddress = parseAddress(addressId);

  let addressString = '';
  if (parsedAddress.flatNumber) addressString += parsedAddress.flatNumber + ' ';
  if (parsedAddress.houseNumber)
    addressString += parsedAddress.houseNumber + ' ';
  if (parsedAddress.houseName) addressString += parsedAddress.houseName + ' ';
  if (parsedAddress.building) addressString += parsedAddress.building + ', ';
  if (parsedAddress.address1) addressString += parsedAddress.address1 + ', ';
  if (parsedAddress.dependentLocality)
    addressString += parsedAddress.dependentLocality + ', ';
  if (parsedAddress.town) addressString += parsedAddress.town + ', ';
  if (parsedAddress.county) addressString += parsedAddress.county + ', ';
  addressString = addressString.replace(/,\s*$/, '');

  return addressString;
};

export default getAddressFromId;
