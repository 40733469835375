// Construct an address ID from the address fields which conform to the EHL (Energy Helpline) standard
export const constructAddressId = (
  flatNumber,
  houseNumber,
  houseName,
  addressLine1,
  addressLine2,
  town,
  county,
  postcode,
) => {
  let address = flatNumber + '|';
  address += houseNumber + '|';
  address += houseName + '|';
  address += addressLine1 + '|';
  address += addressLine2 + '|';
  address += town + '|';
  address += county + '|';
  address += postcode + '||||||||';

  return address;
};

// Adhere to EHL address field policy.  Ensure combined address fields don't exceed 50 characters:
// Take house name, number, flat name, addressLine1, addressLine2 and then concatenate them with a comma and a space
// between each. The resulting string cannot be longer than 50 chars.
export const isAddressEhlCompliant = (
  flatNumber,
  houseNumber,
  houseName,
  addressLine1,
  addressLine2,
) => {
  const address = [
    flatNumber,
    houseNumber,
    houseName,
    addressLine1,
    addressLine2,
  ]
    .filter(Boolean)
    .join(', ');
  return address.length <= 50;
};

export const getAddressById = (id, addresses) => {
  let foundAddress = {};

  addresses.forEach(address => {
    if (address.apiId === id) {
      foundAddress = address;
    }
  });

  return foundAddress;
};
