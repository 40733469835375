import * as Yup from 'yup';
import styled from 'styled-components';
import BaseConnectionContainer from './BaseConnectionContainer';
import serialNumberExample from '../../../assets/images/serial-number-example.png';

const AlphaConnectionContainer = () => {
  const fields = [
    {
      name: 'api_username',
      label: 'Serial number',
      validation: Yup.string()
        .matches(
          /^[A-Z0-9]{1,20}$/,
          'Serial number must be alpha-numeric and up to 20 characters',
        )
        .required('Please enter the serial number'),
    },
    {
      name: 'api_password',
      label: 'Check code',
      validation: Yup.string()
        .matches(
          /^[A-Z0-9]{1,8}$/,
          'Check code must be alpha-numeric and up to 8 characters',
        )
        .required('Please enter the check code'),
    },
  ];

  const StyledImg = styled.img`
    width: 100%;
    margin: 0 auto;
    display: block;
    margin-top: 1rem;
  `;

  return (
    <BaseConnectionContainer
      fields={fields}
      inverterManufacturer="AlphaESS"
      exampleModalContent={
        <>
          <p>
            Serial number and check code can be found on a sticker on the system
            - usually on the right side of the top unit. See the example below:
          </p>
          <StyledImg src={serialNumberExample} alt="Serial number example" />
        </>
      }
    />
  );
};

export default AlphaConnectionContainer;
