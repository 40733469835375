export const EXPORT_LIMIT = 3.6;

const getBatteryMaxDischargePower = (
  batteryMaxDischargePower,
  hasExportLimit = false,
) => {
  if (batteryMaxDischargePower <= EXPORT_LIMIT) {
    return batteryMaxDischargePower;
  }
  if (hasExportLimit && batteryMaxDischargePower > EXPORT_LIMIT) {
    return EXPORT_LIMIT;
  }
  return batteryMaxDischargePower;
};

export default getBatteryMaxDischargePower;
