import styled from 'styled-components';
import withPageTitle from '../../hocs/withPageTitle';
import errorGraphic from '../../assets/svg/404-error-page.svg';

const NotFoundContainer = () => {
  const Graphic = styled.img`
    width: 100%;
    height: auto;
    padding-top: 40px;
  `;

  return (
    <div>
      <Graphic src={errorGraphic} alt="404 error page not found" />
    </div>
  );
};

export default withPageTitle('Page Not Found', NotFoundContainer);
