import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../context/AppContext';

const withPageTitle = (pageTitle, WrappedComponent) => {
  const WithPageTitle = props => {
    const location = useLocation();
    const { setPageTitle } = useContext(AppContext);

    useEffect(() => {
      setPageTitle(pageTitle);
      document.title = pageTitle;
    }, [location]);

    return <WrappedComponent {...props} />;
  };
  return WithPageTitle;
};

export default withPageTitle;
