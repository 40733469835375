import styled from 'styled-components';
import withPageTitle from '../../hocs/withPageTitle';
import Accordion from '../../ui/Accordion';
import { StyledH3 } from '../../ui/Styled';

const Container = styled.div`
  margin-top: 24px;
`;

const StyledExampleContainer = styled.div`
  border: 1px solid #afb6b9;
  border-radius: 8px;
  padding: 8px 12px;

  p {
    margin-bottom: 0;
  }
`;

const HelpContainer = () => {
  const faqs = [
    {
      title: 'How does Loop Optimise work?',
      content: (
        <>
          <p>
            Loop Optimise uses a combination of energy cost forecasts, solar
            generation data, household consumption patterns, and other relevant
            information to determine the best way to utilise your battery. The
            goal is to maximise your earnings.
          </p>
          <p>
            This process is continuous and adaptive, meaning Loop Optimise makes
            decisions throughout the day. If the weather or other conditions
            change unexpectedly, our system adjusts its strategy. Loop Optimise
            is always working to stay on top of these decisions, ensuring you
            get the most benefit from your battery.
          </p>
        </>
      ),
    },
    {
      title:
        'What are the benefits of Loop Optimise vs making manual adjustments?',
      content: (
        <>
          <p>
            Working out the best way to manage your battery can be complex and
            time-consuming. With so many variables and fluctuations, managing a
            home battery efficiently often requires as much effort as a
            full-time job, especially on some tariffs!
          </p>
          <p>
            Loop Optimise simplifies this process by doing all the calculations
            for you and automatically instructing your battery on the best
            actions to take. This means you don't have to spend time manually
            adjusting your battery settings. Loop Optimise handles everything
            seamlessly, ensuring your battery is always used in the most
            efficient way possible, maximising your savings without you having
            to lift a finger.
          </p>
        </>
      ),
    },
    {
      title: 'Do I need to inform my battery manufacturer I’m taking part?',
      content: (
        <>
          <p>
            For our current list of supported manufacturers, typically you don’t
            need to inform them. However, we will handle this process on your
            behalf with your permission.
          </p>
          <p>
            If there are any changes to this arrangement, we will promptly
            inform you.
          </p>
        </>
      ),
    },
    {
      title: 'Does Loop Optimise affect my battery warranty?',
      content: (
        <>
          <p>
            The impact on your battery warranty depends on the manufacturer. We
            strive to operate within the limitations specified by these
            warranties.
          </p>
          <p>
            However, it's important to note that the behaviour of your battery
            may differ based on its make and model. We are mindful of these
            differences and aim to ensure that Loop Optimise operates in a
            manner that aligns with the warranty terms of your specific battery.
          </p>
        </>
      ),
    },
    {
      title: 'Does Loop Optimise impact my battery life?',
      content: (
        <>
          <p>
            Battery health is a complex matter with numerous factors to
            consider. One commonly known factor is the number of cycles a
            battery undergoes daily. However, this is just one aspect among many
            that contribute to understanding overall battery health. While
            monitoring battery health may not be part of the beta trial
            currently, we are actively exploring optimal methods to monitor and
            enhance battery longevity.
          </p>
          <p>
            For instance, strategies such as lowering the average daily State of
            Charge (SOC) or adjusting cycle counts during cold winter days to
            prevent battery temperature from dropping too low are being
            considered. These approaches aim to promote better battery health
            over time. As we continue to develop Loop Optimise, we're focused on
            implementing practices that support the longevity of your battery.
          </p>
        </>
      ),
    },
    {
      title: 'How do I know if Loop Optimise is saving me money?',
      content: (
        <>
          <p>
            Figuring out the savings from solar and battery investments can be
            tricky, especially with changing energy markets. Initially, when you
            install solar panels and a home battery, you're given an estimate of
            how long it will take to recover your costs. However, this can vary
            due to market fluctuations.
          </p>
          <p>
            To understand how much Loop Optimise is saving you, we compare your
            actual import and export costs using the system to what they would
            be without it. This comparison uses your real energy consumption and
            solar generation data, giving you a clear idea of the financial
            benefits.
          </p>
        </>
      ),
    },
  ];

  return (
    <Container>
      <div>
        <StyledH3>FAQs</StyledH3>
        <Accordion data={faqs} />
      </div>

      <Container>
        <StyledH3>Examples</StyledH3>
        <StyledExampleContainer>
          <p>
            View the{' '}
            <a href="https://loop.homes/loop-optimise-example/">
              Loop Optimise example
            </a>
            .
          </p>
        </StyledExampleContainer>
      </Container>

      <Container>
        <StyledH3>Support</StyledH3>
        <StyledExampleContainer>
          <p>
            Need help?{' '}
            <a href="https://kb.loop.homes/kb-tickets/new">Contact support</a>.
          </p>
        </StyledExampleContainer>
      </Container>
    </Container>
  );
};

export default withPageTitle('Loop Optimise Help', HelpContainer);
