import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import navItems from '../constants/navItems';

const Wrapper = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const StyledMobileNavWrapper = styled.div`
  position: fixed;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #0c1f28;
  color: #fff;
  transition: right 0.3s ease-in-out;
  padding: 1rem;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin: 15px 0;
  }

  @media (min-width: 576px) {
    padding: calc(1rem + 15px);
  }
`;

const StyledButton = styled.button`
  position: relative;
  z-index: 1001;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 16px;
  padding: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;

  span {
    position: absolute;
    width: 24px;
    height: 4px;
    background-color: #fff;
    left: 0;
    border-radius: 1px;
    transition: all 0.3s ease;

    &:first-child {
      top: ${props => (props.isOpen ? '8px' : '0px')};
      transform: ${props => (props.isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      top: 8px;
      opacity: ${props => (props.isOpen ? '0' : '1')};
    }

    &:last-child {
      top: ${props => (props.isOpen ? '8px' : '16px')};
      transform: ${props => (props.isOpen ? 'rotate(45deg)' : 'rotate(0)')};
    }
  }
`;

const StyledNav = styled.nav`
  margin-top: ${({ isAdmin }) => (isAdmin ? '145px' : '90px')};

  @media (min-width: 576px) {
    margin-top: ${({ isAdmin }) => (isAdmin ? '135px' : '80px')};
  }
`;

const StyledNavItem = styled.li`
  font-weight: bold;
  color: #fff;
  font-size: 16px;

  &:not(:first-child) {
    margin-top: 30px;

    @media (min-width: 576px) {
      margin-top: 40px;
    }
  }

  a {
    text-decoration: none;
    color: ${props => (props.active ? '#FF0085' : '#FFF')};
    transition: color 0.3s ease;

    &:hover {
      color: #ff0085;
    }
  }
`;

const MobileNav = ({ isAdmin }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  // Handle body scroll lock/unlock
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Cleanup to ensure body overflow is restored when the component is unmounted
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  return (
    <Wrapper>
      <StyledButton onClick={toggleNav} isOpen={isOpen}>
        <span></span>
        <span></span>
        <span></span>
      </StyledButton>
      <StyledMobileNavWrapper isOpen={isOpen}>
        <StyledNav isAdmin={isAdmin}>
          <ul>
            {navItems.map((item, index) => (
              <StyledNavItem key={index} active={item.active}>
                <a href={item.path}>{item.name}</a>
              </StyledNavItem>
            ))}
          </ul>
        </StyledNav>
      </StyledMobileNavWrapper>
    </Wrapper>
  );
};

export default MobileNav;
