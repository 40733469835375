import React, { useEffect } from 'react';
import AppLayout from '../ui/Layout/AppLayout';
import { Card } from 'react-bootstrap';
import LoopSpinner from '../ui/LoopSpinner';
import { signOut } from '../utils/auth';

const SignOutContainer = ({ history }) => {
  useEffect(() => {
    signOut(history);
  }, []);
  return (
    <AppLayout backgroundTint="blue">
      <Card className="p-3 p-sm-4">
        <LoopSpinner />
      </Card>
    </AppLayout>
  );
};
export default SignOutContainer;
