import styled from 'styled-components';
import RippleLoader from './RippleLoader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Card = styled.div`
  border: 1px solid #afb6b9;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const LoadingCard = () => (
  <Container>
    <Card>
      <RippleLoader width="100%" />
      <RippleLoader width="75%" />
      <RippleLoader width="50%" />
      <RippleLoader width="25%" />
    </Card>
  </Container>
);

export default LoadingCard;
