import styled from 'styled-components';

const StyledTitle = styled.h3`
  font-size: 14px;
  font-weight: bold;
  color: #212529;
  margin-bottom: 2px;
  text-align: center;

  @media (min-width: 576px) {
    text-align: left;
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

export default StyledTitle;
