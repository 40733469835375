import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledParagraph = styled.p`
  border-bottom: 1px solid #afb6b9;
  height: 13px;
`;

const StyledSpan = styled.span`
  background: #fff;
`;

const SpacerHr = ({ label, block }) => (
  <StyledParagraph className={`text-center mb-4 ${block || 'mx-3'}`}>
    {label && <StyledSpan className="px-3">{label}</StyledSpan>}
  </StyledParagraph>
);

SpacerHr.propTypes = {
  label: PropTypes.string,
  block: PropTypes.bool,
};

SpacerHr.defaultProps = {
  block: false,
};

export default SpacerHr;
