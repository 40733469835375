import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledLabelDiv = styled.div`
  padding-top: 2px;
  font-weight: normal;
`;

const path =
  'M10 20.286c5.52 0 10-4.48 10-10 0-5.521-4.48-10-10-10s-10 4.479-10 10c0 5.52 4.48 10 10 10zm1.198-7' +
  '.917H8.789c-.247 0-.443-.143-.443-.325l-.221-8.086c0-.092.04-.183.13-.235a.501.501 0 01.313-.104h2.864c.117 0 .' +
  '235.04.313.104.09.052.13.143.13.235l-.234 8.086c-.013.182-.209.325-.443.325zm.065 4.583h-2.5a.434.434 0 01-.43-' +
  '.43V14.05c0-.235.196-.43.43-.43h2.5c.221 0 .404.195.404.43v2.474c0 .234-.183.43-.404.43z';

const ErrorIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    className="mr-2">
    <path fill="#FF5A35" d={path}></path>
  </svg>
);

const ErrorLabel = ({ label, showIcon = true, pad = true }) => (
  <div className={`error-label ${pad && 'mt-2'} d-flex`}>
    {showIcon && <div className="flex-shrink">{ErrorIcon}</div>}
    <StyledLabelDiv className="flex-grow">{label}</StyledLabelDiv>
  </div>
);

ErrorLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ErrorLabel;
