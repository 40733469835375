import React, { useState } from 'react';
import { InputGroup, Button, FormControl } from 'react-bootstrap';
import styled from 'styled-components';
import { light, primary, white } from '../theme/colors';

const StyledInputGroup = styled(InputGroup)`
  width: 210px;
  margin-top: 12px;

  input.form-control {
    background-color: ${white};
    height: 41px;
    border-radius: 4px;
    border-right: none;
    border-color: ${light};
    font-size: 14px;

    @media (min-width: 576px) {
      font-size: 16px;
    }

    &:focus {
      border-color: ${light};
      box-shadow: none;
      outline: none;
    }

    &[disabled] {
      background-color: #f8f9fa;
      opacity: 0.65;
    }
  }

  .input-group-append {
    button {
      height: 41px;
      width: 32px;
      border: none;
      border: 1px solid ${light};
      border-left: none;
      border-right: none;
      color: ${light};
      font-weight: normal;
      background-color: transparent;

      &:hover {
        background-color: ${light} !important;
        color: ${white} !important;
      }

      &:active {
        background-color: ${primary} !important;
        border-color: ${primary} !important;
      }

      &:disabled {
        background-color: transparent;
        color: ${light};
        opacity: 1;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-right: 1px solid ${light};
      }
    }
  }
`;

const PercentageInput = ({
  initialValue = 10,
  min = 10,
  max = 100,
  step = 5,
  onUpdate,
  disabled = false,
}) => {
  const [value, setValue] = useState(initialValue);

  const handleIncrement = () => {
    if (value < max) {
      const newValue = value + step;
      setValue(newValue);
      onUpdate(newValue);
    }
  };

  const handleDecrement = () => {
    if (value > min) {
      const newValue = value - step;
      setValue(newValue);
      onUpdate(newValue);
    }
  };

  return (
    <StyledInputGroup>
      <FormControl
        type="text"
        value={`${value}%`}
        readOnly
        disabled={disabled}
        style={{ textAlign: 'left', padding: '6px 12px' }}
      />
      <InputGroup.Append>
        <Button
          variant="outline-secondary"
          onClick={handleDecrement}
          disabled={disabled || value <= min}>
          -
        </Button>
        <Button
          variant="outline-secondary"
          onClick={handleIncrement}
          disabled={disabled || value >= max}>
          +
        </Button>
      </InputGroup.Append>
    </StyledInputGroup>
  );
};

export default PercentageInput;
