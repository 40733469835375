import styled, { keyframes } from 'styled-components';

const rippleAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const LoaderContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 9999px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Ripple = styled.div`
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
  background: linear-gradient(to right, transparent, white, transparent);
  animation: ${rippleAnimation} 1.5s infinite;
`;

const RippleLoader = ({
  width = '100%',
  height = '1rem',
  backgroundColor = 'rgba(175, 182, 185, 0.2)',
}) => {
  return (
    <LoaderContainer
      width={width}
      height={height}
      backgroundColor={backgroundColor}>
      <Ripple />
    </LoaderContainer>
  );
};

export default RippleLoader;
