import { useContext } from 'react';
import InformationPanel from './InformationPanel';
import { AppContext } from '../../../context/AppContext';

const AdditionalUserInformation = () => {
  const { userDetails } = useContext(AppContext);
  return (
    <InformationPanel
      title="Additional user information (Admin mode):"
      isLoading={!userDetails?.user_sub}
      variant="secondary">
      <li>User sub: {userDetails?.user_sub}</li>
    </InformationPanel>
  );
};

export default AdditionalUserInformation;
