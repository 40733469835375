$input-height: 50px;

.verification-input__container {
  position: relative;
}

.verification-input__container--default {
  max-width: 500px;
}

.verification-input {
  height: 100%;
  position: absolute;
  left: -2000px;
  opacity: 0;
  transform: scale(0);
}

.verification-input--debug {
  left: 0;
  bottom: -70px;
  opacity: 1;
  transform: scale(1);
}

.verification-input__characters {
  display: flex;
}

.verification-input__characters--default {
  height: $input-height;
}

.verification-input__character {
  height: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.verification-input__character--default {
  font-size: 36px;
  line-height: $input-height;
  text-align: center;
  background-color: white;
  margin-left: 8px;
  border: 1px solid black;
  cursor: default;
  user-select: none;
  box-sizing: border-box;

  &:first-child {
    margin-left: 0;
  }

  @media (min-width : 768px) {
    margin-left: 16px;
  }
}

.verification-input__character--inactive--default {
  color: dimgray;
  background-color: lightgray;
}

.verification-input__character--selected--default {
  border-color: cornflowerblue;
  color: cornflowerblue;
}

.verification-input__error {
  color: red;
  margin-top: 8px;
}
