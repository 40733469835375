const futureInstruction = str => {
  switch (str) {
    case 'discharge':
      return 'Discharging';
    case 'hold':
      return 'Holding';
    default:
      return 'Charging';
  }
};

export default futureInstruction;
