import { useEffect, useState } from 'react';

/**
 * Adapted from https://dev.to/selbekk/persisting-your-react-state-in-9-lines-of-code-9go
 * @param {String} key
 * @param {*} defaultValue
 * @return {Array}
 */
function usePersistedState(key, defaultValue) {
  const [state, setState] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem(key)) || defaultValue;
    } catch (e) {
      return defaultValue;
    }
  });
  useEffect(
    () => localStorage.setItem(key, JSON.stringify(state)),
    [key, state],
  );
  return [state, setState];
}

export default usePersistedState;
