import styled from 'styled-components';
import { black, primary } from '../../theme/colors';

const StyledAnchor = styled.a`
  border: 2px solid ${black};
  color: ${black};
  text-decoration: none !important;
  font-weight: bold;
  width: 22px;
  height: 22px;
  display: block;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  line-height: 14px;
  align-items: center;
  justify-content: center;
  display: flex;

  &:hover {
    border-color: ${primary};
  }
`;

export default StyledAnchor;
