import PropTypes from 'prop-types';

const contentfulPropTypes = {
  contentfulTag: PropTypes.string,
  contentType: PropTypes.string,
  loading: PropTypes.bool,
  fetched: PropTypes.bool,
  error: PropTypes.any,
  contentful: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    body: PropTypes.string,
    bodyEnd: PropTypes.string,
    primaryButtonText: PropTypes.string,
    secondaryButtonText: PropTypes.string,
    link: PropTypes.string,
    utilityText: PropTypes.string,
    generateLabel: PropTypes.string,
    generateText: PropTypes.string,
    insulateLabel: PropTypes.string,
    insulateText: PropTypes.string,
    electrifyLabel: PropTypes.string,
    electrifyText: PropTypes.string,
  }),
};
export default contentfulPropTypes;
