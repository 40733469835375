import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import Accordion from '../../../ui/Accordion';
import LoadingCard from '../../LoadingCard';
import { primary, secondary } from '../../../theme/colors';
import moment from 'moment';
import textOrPlaceholder from '../../../utils/textOrPlaceholder';

const Container = styled.div`
  margin-top: 24px;
`;

const Title = styled.h3`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #212529;
  margin-bottom: 5px;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const SavingsGrid = styled.div`
  margin-bottom: 24px;

  @media (min-width: 576px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
`;

const SavingsAmount = styled.h3`
  font-size: 24px;
  font-weight: bold;
  color: ${props => (props.isSaving ? secondary : primary)};
  margin-bottom: 8px;

  @media (min-width: 576px) {
    font-size: 32px;
  }
`;

const SavingsDescription = styled.p`
  font-size: 14px;
  line-height: 1.5;
`;

const BulletList = styled.ul`
  padding-left: 20px;
  margin-top: 16px;
`;

const BulletItem = styled.li`
  margin-bottom: 8px;
`;

const SavingsSummary = ({
  savings,
  total,
  fetchedAnalyticsData,
  fetchedLatestAnalyticsData,
  userStartDate,
}) => {
  if (savings === undefined) {
    savings = 0;
  }
  if (total === undefined) {
    total = 0;
  }
  const accordionData = [
    {
      title: 'How did Loop Optimise make this saving?',
      content: (
        <>
          <p>
            Loop Optimise forecasts how much energy your solar panels will
            generate and how much electricity your house will use.
          </p>
          <p>Loop then tells your battery to:</p>

          <BulletList>
            <BulletItem>
              Charge from the grid when electricity is cheap
            </BulletItem>
            <BulletItem>
              Use energy from the battery when electricity is expensive
            </BulletItem>
            <BulletItem>
              Export to the grid when export prices are high
            </BulletItem>
          </BulletList>
        </>
      ),
    },
  ];

  return (
    <Container>
      {!fetchedAnalyticsData && !fetchedLatestAnalyticsData ? (
        <div className="mb-3">
          <LoadingCard />
        </div>
      ) : (
        <SavingsGrid>
          <div>
            <Title>{savings >= 0 ? 'Daily Savings' : 'Daily Costs'}</Title>
            <SavingsAmount
              isSaving={
                savings >= 0
              }>{`£${textOrPlaceholder(savings, Math.abs(savings).toFixed(2))}`}</SavingsAmount>
            <SavingsDescription>
              ↑ Savings compared to your battery's default settings
            </SavingsDescription>
          </div>
          <div>
            <Title>{total >= 0 ? 'Total Savings' : 'Total Costs'}</Title>
            <SavingsAmount isSaving={total >= 0}>
              {`£${textOrPlaceholder(total, Math.abs(total).toFixed(2))}`}
            </SavingsAmount>
            <SavingsDescription>
              ↑ Savings since you joined Loop Optimise{' '}
              {userStartDate && (
                <>on {moment(userStartDate).format('DD/MM/YY')}</>
              )}
            </SavingsDescription>
          </div>
        </SavingsGrid>
      )}

      <Accordion data={accordionData} />
    </Container>
  );
};

export default SavingsSummary;
