import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import AppLayout from '../ui/Layout/AppLayout';
import AdminContainer from './App/AdminContainer';
import TodayContainer from './App/TodayContainer';
import SavingsContainer from './App/SavingsContainer';
import EnergyFlowContainer from './App/EnergyFlowContainer';
import SettingsContainer from './App/SettingsContainer';
import HelpContainer from './App/HelpContainer';
import AppCard from '../ui/AppCard';
import NotFoundContainer from './App/NotFoundContainer';
import DatePickerLayout from '../ui/Layout/DatePickerLayout';
import { useContext, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { AppContext } from '../context/AppContext';
import { DisconnectAlert, EventAlert } from '../ui/Styled/AlertBarPreset';

const DatePickerRoutes = () => (
  <DatePickerLayout>
    <Switch>
      <Route
        path="/app/savings/:timestamp?"
        component={SavingsContainer}
        exact
      />
      <Route
        path="/app/energy-flow/:timestamp?"
        component={EnergyFlowContainer}
        exact
      />
    </Switch>
  </DatePickerLayout>
);

const AppContainer = () => {
  const history = useHistory();
  const { userSub, isAdmin, setIsAdmin } = useContext(AppContext);
  useEffect(() => {
    const asyncUseEffect = async () => {
      const user = await Auth.currentAuthenticatedUser();
      if (!!user?.attributes && 'custom:admin_secret' in user.attributes) {
        setIsAdmin(true);
        if (!userSub) {
          history.push('/app/admin');
        }
      }
    };
    asyncUseEffect();
  }, []);
  return (
    <>
      {false && <DisconnectAlert />}
      {false && <EventAlert />}
      <AppLayout backgroundTint="blue" hideSupport>
        <Switch>
          {isAdmin && (
            <Route path="/app/admin" component={AdminContainer} exact />
          )}
          <Route path="/app/*">
            <AppCard>
              <Switch>
                <Route path="/app/today" component={TodayContainer} exact />
                <Route
                  path={['/app/savings', '/app/energy-flow']}
                  component={DatePickerRoutes}
                />
                <Route
                  path="/app/settings"
                  component={SettingsContainer}
                  exact
                />
                <Route path="/app/help" component={HelpContainer} exact />
                <Route path="*" component={NotFoundContainer} />
              </Switch>
            </AppCard>
          </Route>
          <Route path="/app" exact>
            <Redirect to="/app/today" />
          </Route>
        </Switch>
      </AppLayout>
    </>
  );
};

export default AppContainer;
