import { Button, Card } from 'react-bootstrap';
import styled from 'styled-components';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const BackButton = styled(Button)`
  position: absolute;
  font-weight: 800;
  text-decoration: none;
  padding: 0;
  align-items: center;
  justify-content: center;
  display: flex;

  .svg-inline--fa {
    font-weight: 800;
    font-size: 22px;
    margin-right: 5px;
  }
`;

const LoginCard = ({ children, onBack }) => {
  return (
    <Card className="p-3 p-sm-4 text-center">
      {!!onBack && (
        <BackButton variant="link" onClick={onBack}>
          <FontAwesomeIcon icon={faChevronLeft} /> Back
        </BackButton>
      )}
      {children}
    </Card>
  );
};

LoginCard.propTypes = {
  children: PropTypes.node,
  onBack: PropTypes.func,
};

export default LoginCard;
