import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { OnboardingContext } from '../context/OnboardingContext';
import getBatteryMaxDischargePower from '../utils/getBatteryMaxDischargePower';
import yesNoToBool from '../utils/yesNoToBool';
import awsconfig from '../config/aws-exports';
import { createUserBatterySolarDetails, KW_PER_PANEL } from '../api/Optimise';
import prepareArrays from '../utils/prepareArrays';

const useSetUserDetails = () => {
  const history = useHistory();
  const {
    postcode,
    battery,
    inverter,
    tariffId,
    hasEv,
    hasSolarDiverter,
    pvSystemSize,
    numPanels,
    solarPanelArrayData,
    batteryManufacturer,
    inverterManufacturer,
    hasExportLimit,
  } = useContext(OnboardingContext);

  const [requestError, setRequestError] = useState(undefined);

  const triggerSetUserDetails = async (next = '/onboarding/connected') => {
    try {
      const batteryMaxChargePower = Math.min(
        battery?.unit_details?.battery_max_charge_power,
        inverter?.unit_details?.battery_max_charge_power,
      );

      const batteryMaxDischargePower = Math.min(
        battery?.unit_details?.battery_max_discharge_power,
        inverter?.unit_details?.battery_max_discharge_power,
      );
      const solarCapacityKw = pvSystemSize || numPanels * KW_PER_PANEL;

      const details = {
        battery_capacity: battery?.unit_details?.battery_capacity,
        battery_degradation_cost:
          inverter?.unit_details?.battery_degradation_cost,
        battery_efficiency: inverter?.unit_details?.battery_efficiency,
        battery_max_charge_power: batteryMaxChargePower,
        battery_max_discharge_power: getBatteryMaxDischargePower(
          batteryMaxDischargePower,
          yesNoToBool(hasExportLimit),
        ),
        battery_soc_lower_limit: 10,
        battery_soc_upper_limit: 100,
        simulation_mode: awsconfig.signup_simulation_mode,
        tariff: tariffId,
        postcode: postcode,
        hardware_details: {
          battery: {
            manufacturer: batteryManufacturer,
            model: battery?.unit_name,
          },
          inverter: {
            manufacturer: inverterManufacturer,
            model: inverter?.unit_name,
          },
        },
        pv_details: {
          solar_inverter_capacity_kw: solarCapacityKw,
          solar_module_capacity_kw: solarCapacityKw,
          arrays: await prepareArrays(solarPanelArrayData, postcode),
        },
        additional_information: {
          has_ev: yesNoToBool(hasEv),
          has_solar_diverter: yesNoToBool(hasSolarDiverter),
        },
      };

      await createUserBatterySolarDetails(details).then(res =>
        history.push(next),
      );
    } catch (error) {
      console.log(error);
      setRequestError(error);
    }
  };

  return { triggerSetUserDetails, requestError };
};

export default useSetUserDetails;
