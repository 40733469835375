import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useContext } from 'react';
import AppNavbar from './AppNavbar';
import { AppContext } from '../context/AppContext';
import { Card } from 'react-bootstrap';

const StyledCard = styled(Card)`
  max-width: 810px !important;
  padding: 24px 32px;

  @media (min-width: 576px) {
    padding: 40px 80px;
  }

  h1 {
    margin-bottom: 0;

    @media (min-width: 576px) {
      margin-bottom: 16px;
    }
  }
`;

const AppCard = ({ children }) => {
  const { pageTitle } = useContext(AppContext);

  return (
    <StyledCard>
      <h1>{pageTitle}</h1>
      <AppNavbar />
      {children}
    </StyledCard>
  );
};

AppCard.propTypes = {
  children: PropTypes.node,
};

export default AppCard;
