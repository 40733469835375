import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { OnboardingContainer, SignOutContainer } from '../containers';
import { OnboardingProvider } from '../context/OnboardingContext';
import { AppProvider } from '../context/AppContext';
import AppContainer from '../containers/AppContainer';

const AppRouter = () => {
  const [isOnboarded, setIsOnboarded] = useState(false);
  const isAuthenticated = true;

  return (
    <AppProvider>
      <OnboardingProvider>
        <Router>
          <Switch>
            <Route path="/sign-out" component={SignOutContainer} />
            {isAuthenticated && <Route path="/app" component={AppContainer} />}
            {/* Router Switch does not support fragments so we have to use two conditionals here */}
            {!isOnboarded && (
              <Route path="/onboarding" component={OnboardingContainer} />
            )}
            {!isOnboarded ? (
              <Route path="/" exact>
                <Redirect to="/onboarding" />
              </Route>
            ) : (
              <Route path="/" exact>
                <Redirect to="/app" />
              </Route>
            )}
          </Switch>
        </Router>
      </OnboardingProvider>
    </AppProvider>
  );
};

export default AppRouter;
