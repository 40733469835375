import * as Yup from 'yup';
import styled from 'styled-components';
import BaseConnectionContainer from './BaseConnectionContainer';
import inverterExample from '../../../assets/images/giv-energy-inverter.png';
import apiTokenExample from '../../../assets/images/giv-energy-manage-api-tokens.png';
import noExpiry from '../../../assets/images/giv-energy-no-expiry.png';
import tokenScopes from '../../../assets/images/giv-energy-token-scopes.png';

const StyledModalSection = styled.div`
  margin-top: 2rem;
`;

const StyledImg = styled.img`
  width: 100%;
  margin: 0 auto;
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const GivEnergyConnectionContainer = () => {
  const fields = [
    {
      name: 'api_username',
      label: 'Inverter ID',
      validation: Yup.string()
        .matches(
          /^[A-Z0-9]{1,20}$/,
          'Inverter ID must be alpha-numeric and up to 20 characters',
        )
        .required('Please enter the Inverter ID'),
    },
    {
      name: 'api_password',
      label: 'API token',
      type: 'password',
      validation: Yup.string().required('Please enter the API token'),
    },
  ];

  return (
    <BaseConnectionContainer
      fields={fields}
      inverterManufacturer="GivEnergy"
      exampleModalContent={
        <>
          <div>
            <h4>
              To locate the <strong>Inverter ID</strong>:
            </h4>
            <ul>
              <li>
                Log in to the GivEnergy{' '}
                <a href="https://givenergy.cloud/login" target="_blank">
                  website
                </a>
              </li>
              <li>
                Go to <strong>My Inverter</strong>
              </li>
              <li>Inverter ID is at the top of the page:</li>
            </ul>
            <StyledImg src={inverterExample} alt="Inverter ID example" />
          </div>

          <StyledModalSection>
            <h4>
              To generate an <strong>API Token</strong>:
            </h4>
            <ul>
              <li>
                Log in to the GivEnergy{' '}
                <a href="https://givenergy.cloud/login" target="_blank">
                  website
                </a>
              </li>
              <li>
                Go to <strong>Account Settings</strong>
              </li>
              <li>
                Click on the <strong>MANAGE ACCOUNT SECURITY</strong> button
              </li>
              <li>
                Scroll until you see the{' '}
                <strong>Manage givenergy.cloud API Tokens</strong> section and
                click on <strong>MANAGE API TOKENS</strong>
              </li>
            </ul>
            <StyledImg src={apiTokenExample} alt="API token example" />
          </StyledModalSection>
          <StyledModalSection>
            <ul>
              <li>
                Click on <strong>GENERATE NEW TOKEN</strong>
              </li>
              <li>
                Name the Token something you will recognise. (This is so that
                you can remember what the token is for in case you wish to
                change or delete it at a later date.)
              </li>
              <li>
                Set the expiry to <strong>No Expiry</strong>. (NOTE: GivEnergy
                will warn against using <strong>No Expiry</strong>, and will
                recommend a limit of 90 days. Either will work, but{' '}
                <strong>No Expiry</strong> will mean you won’t have to
                regenerate a token again in 3 months.)
              </li>
            </ul>
            <StyledImg src={noExpiry} alt="No expiry" />
          </StyledModalSection>
          <StyledModalSection>
            <ul>
              <li>
                Within <strong>Token Scopes</strong>, select{' '}
                <strong>api:inverter: Full control over your inverters</strong>
              </li>
            </ul>
            <StyledImg src={tokenScopes} alt="No expiry" />
            <ul>
              <li>
                Click <strong>CREATE TOKEN</strong>
              </li>
            </ul>
          </StyledModalSection>
        </>
      }
    />
  );
};

export default GivEnergyConnectionContainer;
