import moment from 'moment';

/**
 * Processes the data for the battery state of charge chart
 *
 * @param {Array} data
 * @returns {Array}
 */
export const processSocChartData = data => {
  return data.map(record => ({
    x: moment(record.time, 'HH:mm:ss').format('HH:mm'),
    y: parseInt(record.battery_soc, 10),
  }));
};

/**
 * Processes the data for the system energy history chart
 *
 * @param {Array} data
 * @returns {Array}
 */
export const processSystemEnergyHistoryChartData = data =>
  data.map(record => ({
    time: moment
      .utc(record.target_datetime_utc * 1000)
      .local()
      .format('HH:mm'),
    'Solar generation': parseFloat(record.solar_generation),
    Consumption: parseFloat(record.load_consumption),
    Imported: parseFloat(record.imported),
    Exported: -parseFloat(record.exported),
  }));

/**
 * Calculates the y domain for the system energy history chart
 *
 * @param {Array} processedData
 * @param {Function} setYDomain
 * @returns {void}
 */
export const calculateYDomain = (processedData, setYDomain) => {
  let minY = 0;
  let maxY = 0;

  processedData.forEach(item => {
    const sum =
      item['Solar generation'] +
      item.Consumption +
      item.Imported +
      item.Exported;
    minY = Math.min(minY, sum, item.Exported);
    maxY = Math.max(
      maxY,
      sum,
      item['Solar generation'],
      item.Consumption,
      item.Imported,
    );
  });

  setYDomain([minY * 1.1, maxY * 1.1]);
};
