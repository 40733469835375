import { ProgressBar } from 'react-bootstrap';
import styled from 'styled-components';
import { infoGradient } from '../theme/colors';

const OuterWrapper = styled.div`
  display: flex;
`;

const InnerEndWrapper = styled.div`
  flex-shrink: 0;
  font-size: 14px;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const InnerMiddleWrapper = styled.div`
  flex: 1;
  margin: 6px 10px 0;
`;

const StyledProgressBar = styled(ProgressBar)`
  border-radius: 6px;
  height: 11px;

  .progress-bar {
    border-radius: 6px;
    background: ${infoGradient};
  }
`;

const OnboardingProgress = ({ percentage }) => (
  <div className="mb-4">
    <OuterWrapper>
      <InnerEndWrapper>Progress: </InnerEndWrapper>
      <InnerMiddleWrapper>
        <StyledProgressBar now={percentage} />
      </InnerMiddleWrapper>
      <InnerEndWrapper>{`${percentage}%`}</InnerEndWrapper>
    </OuterWrapper>
  </div>
);

export default OnboardingProgress;
